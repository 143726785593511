/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { StoreContext } from "../Store";
import { Input, Select, NeeNorSelect } from "../Input/Input.js";
import { getNewLoad, getNewLoadDropdown, getNewLoadNeeNorDropdown, getEidr, getPortProcessDropdown, checkNewloadDuplicate } from "../apiCalls";
import { Container, Popover, OverlayTrigger, PopoverContent } from "react-bootstrap";
import ControlledGoodsModal from "../ControlledGoodsModal";
import AdjustmentsModal from '../AdjustmentsModal';
import { useNavigate, useParams } from 'react-router-dom';
import ClientIdPage from "./ClientIdPage";
import { useSecurity } from '../Security/security';
import WarningPage from '../WarningPage'
import blankCheckbox from '../../images/Grey_cross.svg'
import checkbox from '../../images/checkmark.png'
import greenAdd from '../../images/grn-btn1.png'
import NeeNorModal from './../Newload/NeerNorModal';
import ConfirmationModal from './../ConfirmationModal';
import isoDateConverter from "./../isoDateConverter.js";


const LoadInfo = ({ customaiteEnabled, nextPage, props, showRequiredFields, updateLoadStatus = null }) => {
  const store = useContext(StoreContext);
  const navigate = useNavigate();
  const { validateRole } = useSecurity();
  const { id } = useParams();
  const [isFreightChargesInco, setIsFreightChargesInco] = useState(false);
  const [checkForm, setCheckForm] = useState(false);
  const formRef = useRef();
  const [freightErrorState, setFreightErrorState] = useState();
  const [showNeeNor, setShowNeeNor] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [dupData, setDupData] = useState([]);
  const [validInvoiceNumberMessage, setValidInvoiceNumberMessage] = useState("");
  const [validTruckNumberMessage, setValidTruckNumberMessage] = useState("");
  const [validContainerMessage, setValidContainerMessage] = useState("");

  //todo: this will be a mapping state for the fields, so we will know which fields are required and which are not
  const [fields, setFields] = useState([
    { name: 'Movement Type', required: true, value: store.directionSelected[0] }]);

    const fieldValidLength = new Map([
      ["InvoiceNumber", 35],
      ["TruckNumber", 35],
      ["Container", 17],
    ]);

    const validateStringLength = (fieldValue, type) => {
      var maxLength = fieldValidLength.get(type);
      const valid = fieldValue ? fieldValue.length <= maxLength : true;
      var message = `Cannot exceed ${maxLength} characters.`;
      switch (type) {
        case "InvoiceNumber":
          setValidInvoiceNumberMessage(valid ? "" : message);
          break;
        case "TruckNumber":
          setValidTruckNumberMessage(valid ? "" : message);
          break;
        case "Container":
          setValidContainerMessage(valid ? "" : message);
          break;
        default:
          return true;
      }
      return valid;
    };
  
      

  const adjustmentsRef = useRef();

  const handleSubmit = (event) => {
    const invoiceValid = validateStringLength(
      store?.invoicenumber[0],
      "InvoiceNumber"
    );
    const truckValid = validateStringLength(store?.truck[0], "TruckNumber");
    const containerValid = validateStringLength(
      store?.container[0],
      "Container"
    );
    setFreightErrorState();
    event.preventDefault();

    if (event.target.checkValidity()) {
      const fc = store.adjustments[0].find((adj) => adj.name === 'freightCharges')
      if (isFreightChargesInco && (fc.amount === '' || fc.currency === '' || fc.amount < 1)) {
        if (fc.amount === '' || fc.currency === '') {
          setShowAdjustmentsModal(true);
          setCheckForm(true);
        }

        else if (fc.amount < 1) {
          setFreightErrorState('loading')
          store.adjustments[1]((prev) => {
            return prev.map((adjustment) => {
              if (adjustment.name === 'freightCharges')
                adjustment.error = "Must be greater than 0."
              return adjustment
            })
          })
        }
      }
      else {
        const payload = {
          consignor: store?.consignor[0],
          consignee: store?.consignee[0],
          invoicenum: store?.invoicenumber[0],
          draftId: store?.loadId[0]
        }
        checkNewloadDuplicate(payload).then((data) => {
          const { duplicate, registered } = data[0];
         
          if (duplicate === true) {
            setDupData(data)
            setShowDuplicateModal(true);
            return;
          } else {
            if (invoiceValid && truckValid && containerValid) {
              nextPage();
            } 
          }
        });
      }
    }
  };

  const handleNext = () => {
    nextPage();
  }

  useEffect(() => {
    if (checkForm) {
      setTimeout(() => {
        formRef.current.reportValidity();
        setCheckForm(false);
      }, 100)
    }
  }, [checkForm])

  useEffect(() => {
    if (freightErrorState === 'loading') {
      if (store.adjustments[0].reduce((acc, cur) => {
        return acc || !!cur.error
      }, false))
        setFreightErrorState('loaded');
    }

  }, [store.adjustments[0], freightErrorState])

  useEffect(() => {
    if (freightErrorState === 'loaded') {
      setFreightErrorState();
      setShowAdjustmentsModal(true);
    }
  }, [freightErrorState])


  const [disableInputs, setDisableInputs] = useState(true);

  const [ShowPort2Input, setShowPort2Input] = useState(false);
  const [showConfirmTadModal, setShowConfirmTadModal] = useState(false);
  const [showControlledGoodsModal, setShowControlledGoodsModal] = useState(false);
  const [showAdjustmentsModal, setShowAdjustmentsModal] = useState(false);
  const [adjustmentsIncoLoaded, setAdjustmentsIncoLoaded] = useState(false);
  const [ffd, setFfd] = useState(false);
  const yesNoOptions = [
    { id: "Y", data: "Yes" },
    { id: "N", data: "No" },
  ];

  const [neeStandingAuthority, setNeeStandingAuthority] = useState(false);
  const [norStandingAuthority, setNorStandingAuthority] = useState(false);

  const [eidrAdjustments, setEidrAdjustments] = useState(null);

  /**
   * Inital data fetch from server
   */
  useEffect(() => {
    async function fetchData() {
      try {
        getNewLoad("fcl").then((data) => { store.loadTypeOptions[1](data); });
        getNewLoad("shipStatus").then((data) => { store.shippingStatusOptions[1](data); });
        getNewLoad("movement").then((data) => { store.movementOptions[1](data?.filter(item => item.data !== 'NI - GB')) });
        getNewLoad("currency").then((data) => { store.currencyOptions[1](data); });
        getNewLoad("incoterm").then((data) => { store.incotermsOptions[1](data); });
        getNewLoad("vat").then((data) => { store.VatOptions[1](data) });
        getNewLoad("pref").then((data) => { store.PreferentialOptions[1](data) })

        if (id) {
          store.loadId[1](id);
          store.isEidr[1](true);
          setHeaderInputs(await getEidr(id), true);
        }
        //RPA
        if (store.newLoadFromFile[0]) { setHeaderInputs(store.newLoadFileData[0]); }

      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);


  useEffect(() => {
    if (store.currencyOptions[0].length !== 0 && store.newLoadFileData[0].Headers && store.newLoadFromFile[0]) {
      updateAdjustments(store.newLoadFileData[0])
    }
    else if (store.currencyOptions[0].length !== 0 && eidrAdjustments) {
      updateAdjustments(eidrAdjustments, true)
    }
  }, [store.currencyOptions[0], store.newLoadFileData[0], eidrAdjustments])

  const updateAdjustments = (returnData, eidr) => {
    adjustmentsRef.current = [
      {
        name: "freightCharges",
        display: "Freight Charges",
        amount: returnData.Headers[0].FreightChargesAmount ?? "",
        currency: eidr ? returnData.Headers[0].FreightChargesCurrency
          : store.currencyOptions[0].find((item) => item.data === returnData.Headers[0].FreightChargesCurrency)?.id ?? "",
        required: false
      },
      {
        name: "insuranceCharges",
        display: "Insurance Charges",
        amount: returnData.Headers[0].InsuranceChargesAmount ?? "",
        currency: eidr ? returnData.Headers[0].InsuranceChargesCurrency
          : store.currencyOptions[0].find((item) => item.data === returnData.Headers[0].InsuranceChargesCurrency)?.id ?? "",
        required: false
      },
      {
        name: "vatAdjustment",
        display: "VAT Adjustment",
        amount: returnData.Headers[0].VatAdjustmentsAmount ?? "",
        currency: eidr ? returnData.Headers[0].VatAdjustmentsCurrency
          : store.currencyOptions[0].find((item) => item.data === returnData.Headers[0].VatAdjustmentsCurrency)?.id ?? "",
        required: false
      },
      {
        name: "otherCharges",
        display: "Other Additions",
        amount: returnData.Headers[0].OtherAdditionsAmount ?? "",
        currency: eidr ? returnData.Headers[0].OtherAdditionsCurrency
          : store.currencyOptions[0].find((item) => item.data === returnData.Headers[0].OtherAdditionsCurrency)?.id ?? "",
        required: false
      },
      {
        name: "invoiceDiscount",
        display: "Other Deductions",
        amount: returnData.Headers[0].OtherDeductionsAmount ?? "",
        currency: eidr ? returnData.Headers[0].OtherDeductionsCurrency
          : store.currencyOptions[0].find((item) => item.data === returnData.Headers[0].OtherDeductionsCurrency)?.id ?? "",
        required: false
      }
    ]
  }

  const setHeaderInputs = (returnData, eidr) => {
    try {
      updateLoadStatus(returnData.Headers[0].loadstatus);
      store.loadId[1](returnData.Headers[0].id);
      store.clientID[1](returnData.Headers[0].clientID)
      store.clientIDerror[1](returnData.Headers[0].clientIDerror)
      store.declarant[1](returnData.Headers[0].Declarant)
      store.directionSelected[1](returnData.Headers[0].movementTypeId);
      store.selectFcl[1](returnData.Headers[0].fclId);
      store.despatch[1](returnData.Headers[0].CountryofDesptachid);
      store.destination[1](returnData.Headers[0].CountryofDestinationid);
      store.exit[1](returnData.Headers[0].ExitPort1id);
      store.entry[1](returnData.Headers[0].EntryPort1id);
      store.exit2[1](returnData.Headers[0].ExitPort2id);
      store.entry2[1](returnData.Headers[0].EntryPort2id);
      store.consignor[1](returnData.Headers[0].Consignorid);
      store.consignee[1](returnData.Headers[0].Consigneeid);
      store.consignorRPA[1](returnData.Headers[0].Consignorid);
      store.consigneeRPA[1](returnData.Headers[0].Consigneeid);
      store.shipStatus[1](returnData.Headers[0].shipStatus);
      store.truck[1](returnData.Headers[0].trucktext);
      store.trailer[1](returnData.Headers[0].trailerSeal);
      store.date[1](returnData.Headers[0].loadingDate && returnData.Headers[0].loadingDate.split("T")[0]);
      store.jobRef[1](returnData.Headers[0].jobRef);
      store.invoicenumber[1](returnData.Headers[0].InvoiceNumber);
      store.invoicecurr[1](returnData.Headers[0].InvoiceCurrencyid);
      store.selectincoterm[1](returnData.Headers[0].Incotermid);
      store.incotermLocation[1](returnData.Headers[0].incotermLocation);
      store.exitPortDefault[1](returnData.Headers[0].exitportdefault);
      store.entryPortDefault[1](returnData.Headers[0].entryportdefault);
      store.GBimport[1](isGBImport(returnData.Headers[0].movementTypeId));
      store.GBexport[1](isGBexport(returnData.Headers[0].movementTypeId));
      store.controlledGoods[1](returnData.Headers[0].controlledGoods);
      store.trailerSeal[1](returnData.Headers[0].trailerSeal);
      store.officeOfDest[1](returnData.Headers[0].officeOfDest);
      store.trailer[1](returnData.Headers[0].trailer);
      store.container[1](returnData.Headers[0].imo);
      store.container[1](returnData.Headers[0].container);
      if (returnData.Headers[0].entryporthybrid) { store.importMixedPortsModal[1](1) };
      if (returnData.Headers[0].exitporthybrid) { store.exitMixedPortsModal[1](1) };
      store.portsModal[1](returnData.Headers[0].portModal);

      setFfd(returnData.Headers[0].ffd);

      if (eidr) setEidrAdjustments(returnData)

    } catch (error) {
      console.error("Error setting header inputs:", error);
      // Handle the error here, such as logging it or showing a user-friendly message
    }
  };

  useEffect(() => {
    try {
      const newDirection = store.directionSelected[0];
      const euToEuViaGB = (typeof newDirection === 'string') ? "1" : 1;

      store.GBimport[1](isGBImport(newDirection));
      store.GBexport[1](isGBexport(newDirection));
      store.RowImport[1](isRowImport(newDirection));
      store.RowExport[1](isRowExport(newDirection));

      if (
        newDirection === "" ||
        newDirection === undefined ||
        newDirection === null
      ) {
        setDisableInputs(true);
        setShowPort2Input(false);
        return;
      }

      setDisableInputs(false);
      getNewLoadDropdown("despatch", newDirection).then((data) => { store.despatchCountryOptions[1](data); });
      getNewLoadDropdown("exit", newDirection).then((data) => { store.exitPortOptions[1](data); });
      getNewLoadDropdown("destination", newDirection).then((data) => { store.entryCountryOptions[1](data); });
      getNewLoadDropdown("entry", newDirection).then((data) => { store.entryPortOptions[1](data); });

      getPortProcessDropdown("portprocessdropdown", newDirection).then((data) => { store.portsModalOptions[1](data); });

      setShowPort2Input(newDirection === euToEuViaGB ? true : false);

    } catch (err) {
      console.log(err);
    }
  }, [store.directionSelected[0]]);

  useEffect(() => {
    if (!store.GBimport[0]) {
      store.incotermLocation[1]('')
    }

  }, [store.GBimport[0]])


  useEffect(() => {
    if (store.GBimport[0]) {
      const selectedEntryPort = store.entryPortOptions[0].find(opt => opt?.id === store.entry[0]);
      if (selectedEntryPort) {
        entryPortChanged(selectedEntryPort);
      }
    }

    if (store.GBexport[0]) {
      const selectedExitPort = store.exitPortOptions[0].find(opt => opt?.id === store.exit[0]);
      if (selectedExitPort) {
        exitPortChanged(selectedExitPort);
      }
    };

  }, [store.directionSelected[0], store.shipStatus[0], store.entry[0], store.exit[0], store.entryPortOptions[0], store.exitPortOptions[0]])

  const entryPortChanged = (newSelection) => {
    if (newSelection) {
      store.importMixedPortsModal[1](newSelection.hybrid);
      if (store.GBimport[0]) {
        if (!newSelection.hybrid) {
          store.portsModal[1]('')
        }
      }
      store.entry[1](newSelection.id);
    } else { store.entry[1](0) }
  }

  const exitPortChanged = (newSelection) => {
    if (newSelection) {
      store.exportMixedPortsModal[1](newSelection.hybrid);
      if (!store.GBimport[0]) {
        if (!newSelection.hybrid) {
          store.portsModal[1]('')
        }
      }
      store.exit[1](newSelection.id);
    } else { store.exit[1](0) }
  }

  const showPortsModaldropDown = () => {
    if (((store.GBimport[0] || store.GBexport[0]) && (store.importMixedPortsModal[0] === 1 || store.exportMixedPortsModal[0] === 1))) {
      // default to GVMS
      store.portsModal[1](1);
      // accompanied/un and not GB-ROI?
      if ((store.shipStatus[0] === 2 || store.shipStatus[0] === '2') && !isGBExportToROI(store.directionSelected[0])) {
        store.portsModal[1](2);
      } else {
        store.portsModal[1](1);
      }
    } else store.portsModal[1](0)
  };

  const updateCurrency = (val) => {
    store.invoicecurr[1](val);
  }

  const isAdjustmentsInco = useMemo(() => {
    if (store.selectincoterm[0]?.length === 0 || (parseInt(store.selectincoterm[0]) > 8 && parseInt(store.selectincoterm[0]) < 11))
      return false;
    return true;
  }, [store.selectincoterm[0], store.directionSelected[0]])

  const isAdjustmentsRequiredInco = useMemo(() => {
    return (isAdjustmentsRoute(store.directionSelected[0])) && store.incotermOptionsRequireAdjustmentsValues[0].includes(store.selectincoterm[0]?.toString())
  }, [store.selectincoterm[0], store.directionSelected[0]])


  useEffect(() => {
    if (store.currencyOptions[0].length > 0) {
      updateAdjustmentsFromIncoTerm();
    }
  }, [store.selectincoterm[0], store.directionSelected[0], store.currencyOptions[0]])

  const updateIncoTerm = (data) => {
    store.selectincoterm[1](data);
    if (data !== '7') { store.incotermLocation[1]('') }
    updateAdjustmentsFromIncoTerm(data);
  }

  const prevIncoterm = useRef(store.selectincoterm[0]);

  useEffect(() => {
    if (prevIncoterm.current !== store.selectincoterm[0] && prevIncoterm.current !== '') {
      store.adjustments[1](prevAdjustments => prevAdjustments.map(adjustment => ({
        ...adjustment,
        amount: '',
        currency: ''
      })));
    } else {
    }
    prevIncoterm.current = store.selectincoterm[0];
  }, [store.selectincoterm[0]]);

  const updateAdjustmentsFromIncoTerm = (data) => {
    const incoterm = data?.toString() ?? store.selectincoterm[0]?.toString()
    if (adjustmentsRef.current) {
      store.adjustments[1](adjustmentsRef.current.map((adjustment) => {
        if (adjustment.name === 'freightCharges')
          adjustment.required = (isAdjustmentsRoute(store.directionSelected[0]) && store.incotermOptionsRequireAdjustmentsValues[0].includes(incoterm))
        return adjustment
      }))
      adjustmentsRef.current = null;
    }
    else {
      store.adjustments[1](store.adjustments[0].map((adjustment) => {
        if (adjustment.name === 'freightCharges')
          adjustment.required = (isAdjustmentsRoute(store.directionSelected[0]) && store.incotermOptionsRequireAdjustmentsValues[0].includes(incoterm))
        return adjustment
      }))
    }
    setIsFreightChargesInco(isAdjustmentsRoute(store.directionSelected[0]) && store.incotermOptionsRequireAdjustmentsValues[0].includes(incoterm))
  }

  return (
    <div>
      {showDuplicateModal && <ConfirmationModal
        header={'Duplicates detected'}
        show={showDuplicateModal}
        onHide={(event) => {
          setShowDuplicateModal(false);
        }}
        onConfirm={handleNext}
        hideCancel={false}
        animation={false}
        centered
      >
        <p>Duplicate combination of the following has been detected.</p>

        <ul>
          <li>Consignee code</li>
          <li>Consignor code</li>
          <li>Invoice number</li>
        </ul>

        <p>Duplicate load details:</p>
        <ul>
          {(dupData?.map((duplication) =>
            <li>
              <span>{duplication?.portalRef}</span> - <span>{isoDateConverter(duplication?.loadDate, true, false, true)}</span> <span>{duplication?.jobStatus}</span>
            </li>
          ))}
        </ul>
        <p>Proceeding to the next step may result in duplication.</p>
      </ConfirmationModal>}
      {false ?
        <WarningPage
          title="Could not proceed..."
          body={<>
            <p style={{ fontWeight: 'bold' }}>{store.clientIDerror[0]}</p>

            <p>For more details please contact us below
              <div className="bold text-eoriblue text-nowrap ms-2 mt-1">P: +44 (0) 333 012 4819</div>
              <div className="bold text-eoriblue ms-2">E: <a className="bold text-eoriblue" href="mailto:cabiesupport@eori.uk?subject=CABIE Issue">CABIEsupport@eori.uk</a></div>
            </p>
          </>}
          onClick={() => {
            navigate('/NewLoadFileUpload')
          }} />
        :
        (
          store.clientID[0] === null && !validateRole({ isCustomer: true }) && !store.isEidr[0]) ?
          <ClientIdPage customaiteEnabled={customaiteEnabled} /> :
          <>
            <Container fluid="lg" className="mb-4 semi-fluid-container">
              <section className="floating-box-newload">
                <div className="floating-box-header-newload">
                  <span>Load Information {!!store.clientID[0] || !!store.clientIDname[0] && !validateRole({ isCustomer: true }) ? (<span style={{ fontSize: '1.0rem', marginTop: '0.3rem' }}>({store.clientIDname[0]})</span>) : null}</span>
                  <div style={{ flexGrow: 1 }}></div>
                </div>
                <form onSubmit={handleSubmit} id="newload">
                  <div>
                    <div id="load-info-form">
                      <section className="inputs-group">
                        <span className="section-title">Movement</span>

                        <Select
                          label="Movement Type"
                          name="direction"
                          data={store.directionSelected[0]}
                          setData={store.directionSelected[1]}
                          options={store.movementOptions[0]}
                          classNameInputField={showRequiredFields ? 'loadInfo__required_fields' : ''}
                          required
                          info={
                            <OverlayTrigger
                              placement="right"
                              trigger={["hover", "focus"]}
                              overlay={
                                <Popover style={{ minWidth: "15rem" }}>
                                  <PopoverContent>
                                    <h6>Movement Type</h6>
                                    <p>A movement type is required before you can begin the process of entering a New Load.</p>
                                  </PopoverContent>
                                </Popover>
                              }
                            >


                              <div className="important-info"></div>
                            </OverlayTrigger>
                          }
                        />

                        <Select
                          label="Full Load or Groupage"
                          name="fcl"
                          data={store.selectFcl[0]}
                          setData={store.selectFcl[1]}
                          required
                          options={store.loadTypeOptions[0]}
                          disabled={disableInputs}
                          info={
                            <OverlayTrigger
                              placement="right"
                              trigger={["hover", "focus"]}
                              overlay={
                                <Popover style={{ minWidth: "15rem" }}>
                                  <PopoverContent>
                                    <h6>Full Load - Groupage</h6>
                                    <p>Full load: an entire trailer or container is used to transport a single customer’s cargo.</p>
                                    <p>Groupage: the parcels of various customers are put together to fill the trailer or container.</p>
                                  </PopoverContent>
                                </Popover>
                              }
                            >
                              <div className="info"></div>
                            </OverlayTrigger>
                          }
                        />

                        <Select
                          label="Country of Despatch"
                          name="countrydespatch"
                          data={store.despatch[0]}
                          setData={store.despatch[1]}
                          required
                          options={store.despatchCountryOptions[0]}
                          filter={1}
                          disabled={disableInputs}
                        />

                        <Select
                          label="Country of Destination"
                          name="countrydestination"
                          data={store.destination[0]}
                          setData={store.destination[1]}
                          required
                          options={store.entryCountryOptions[0]}
                          filter={1}
                          disabled={disableInputs}
                        />

                        <Input
                          label="Office of Destination (transit)"
                          name="Office of Destination"
                          type="text"
                          value={store.officeOfDest[0]}
                          onChange={(e) => store.officeOfDest[1](e.target.value)}
                          disabled={disableInputs}
                          className="formattedText"
                          info={
                            <OverlayTrigger
                              placement="right"
                              trigger={["hover", "focus"]}
                              overlay={
                                <Popover style={{ minWidth: "35rem" }}>
                                  <PopoverContent>
                                    <h6>Common Transit Procedure</h6>
                                    <p>Common transit is a customs procedure that facilitates the movement of goods to, from, through or between the countries that have signed the Common Transit Convention. This includes movements to, from, through or between all EU member states.</p>
                                    <h6>Union Transit Procedure</h6>
                                    <p>Union transit is a customs procedure that allows goods not in free circulation in the EU, to move within the EU, while customs duties or other charges are suspended. This may apply to goods moving to, from or through Northern Ireland.</p>
                                    <p>It also allows goods that are in free circulation in the EU to move from a point of departure in the EU, to point of destination in the EU, through the territory of a third country, such as Great Britain Great Britain (England, Wales and Scotland). This may apply to goods moving to and from Northern Ireland.</p>
                                    <p><i>To find out more, click the icon.</i></p>
                                  </PopoverContent>
                                </Popover>
                              }
                            >
                              <a
                                target="_blank"
                                href="https://ec.europa.eu/taxation_customs/dds2/col/col_consultation_location.jsp?Lang=en"
                                rel="noreferrer"
                              >
                                <div className="info"></div>
                              </a>
                            </OverlayTrigger>
                          }
                        />
                      </section>

                      <section className="inputs-group">
                        <span className="section-title">Direction</span>
                        <Select
                          label="Exit Port 1"
                          name="exitport1"
                          data={store.exit[0]}
                          onChangeAsObject={exitPortChanged}
                          options={store.exitPortOptions[0]}
                          filter={1}
                          disabled={disableInputs || store.RowImport[0]}
                          required={!ffd}
                        />

                        <Select
                          label="Entry Port 1"
                          name="entryport1"
                          data={store.entry[0]}
                          onChangeAsObject={entryPortChanged}
                          options={store.entryPortOptions[0]}
                          filter={1}
                          disabled={disableInputs || store.RowExport[0]}
                          required
                        />

                        {((store.GBimport[0] || store.GBexport[0]) && (store.importMixedPortsModal[0] === 1 || store.exportMixedPortsModal[0] === 1)) && (
                          <>
                            <Select
                              label="Port Model"
                              name="mixedPortsModalentry"
                              data={store.portsModal[0]}
                              setData={store.portsModal[1]}
                              options={store.portsModalOptions[0]}
                              filter={1}
                              disabled={disableInputs}
                              required
                              info={
                                <OverlayTrigger
                                  placement="top"
                                  trigger={["hover", "focus"]}
                                  overlay={
                                    <Popover style={{ minWidth: "50rem" }}>
                                      <PopoverContent>
                                        <h5>Port Model</h5>
                                        <p></p>
                                        <p><b>There are two models for customs control in operation at UK ports and border locations in use today:</b></p>
                                        <p></p>
                                        <p>
                                          <b>GVMS</b> - Goods must have a customs procedure associated to them. This can be a pre-lodged entry, transit document or entry in declarants records (EIDR) or another customs procedure. This must be then added to the GMR via GVMS otherwise the consignment or vehicle will not be allowed to board the vessel or train.
                                        </p>
                                        <p>
                                          <b>Temporary storage (Inventory Linked)</b> - Goods will be entered into a port or border locations inventory system – The goods must be claimed by a nominated agent in order to release them into a customs procedure such as an import declaration or transit.
                                        </p>

                                        <p></p>
                                        <p>Most GB ports operate a mixed model and offer both GVMS or Temporary storage (Inventory Linked) as methods of customs control. If the port is a hybrid and offers a choice, based upon the accompanied status of the consignment we have assumed the model for you. However the choice between GVMS and Temporary storage (Inventory Linked) can be made by a carrier or haulier, please check before selecting the model you wish to use.
                                        </p>
                                        <p></p>
                                      </PopoverContent>
                                    </Popover>
                                  }
                                >
                                  <div className="important-info"></div>
                                </OverlayTrigger>
                              }
                            />
                          </>
                        )}

                        <div
                          style={{
                            display: ShowPort2Input === true ? "block" : "none",
                          }}
                        >
                          <Select
                            label="Exit Port 2"
                            name="exitport2"
                            data={store.exit2[0]}
                            setData={store.exit2[1]}
                            options={store.entryPortOptions[0]}
                            filter={1}
                            required={ShowPort2Input === true ? true : undefined}
                          />

                          <Select
                            label="Entry Port 2"
                            name="entryport2"
                            data={store.entry2[0]}
                            setData={store.entry2[1]}
                            options={store.exitPortOptions[0]}
                            filter={1}
                            required={ShowPort2Input === true ? true : undefined}
                          />
                        </div>

                        <NeeNorSelect
                          label="Consignor Code"
                          name="consignor"
                          data={store.consignor[0]}
                          setData={store.consignor[1]}
                          options={store.norOptions[0]}
                          disabled={disableInputs}
                          refresh={async (search, skip) => {
                            if (!store.directionSelected[0]) return undefined;
                            const options = await getNewLoadNeeNorDropdown("neenor", store.directionSelected[0], "consignor", search, skip, store.consignorRPA[0]);
                            setNorStandingAuthority(options.find((opt) => opt.id === store.consignor[0])?.standingAuthority)
                            return options;
                          }}
                          dependencies={[store.directionSelected[0]]}
                          onChangeAsObject={(opt) => setNorStandingAuthority(opt.standingAuthority)}
                          required
                          info={
                            <div className="d-flex flex-row ">
                              <OverlayTrigger
                                placement="right"
                                trigger={["hover", "focus"]}
                                overlay={
                                  <Popover style={{ minWidth: "30rem" }}>
                                    <PopoverContent>
                                      <h6>Consignors - Consignees</h6>
                                      <p>In a consignment process, it encompasses the sending of goods from a consignor to a consignee. The person who ships the goods is the consignor (exporter), while the receiver is the consignee (importer).</p>
                                    </PopoverContent>
                                  </Popover>
                                }
                              >
                                <div className="info" style={{ marginRight: '5px' }}></div>
                              </OverlayTrigger>

                              {norStandingAuthority ? <img src={checkbox} alt="" width="11px" height="9px" /> : <img src={blankCheckbox} alt="" width="11px" height="9px" />}
                            </div>
                          }
                        />

                        <NeeNorSelect
                          label="Consignee Code"
                          name="consignee"
                          data={store.consignee[0]}
                          setData={store.consignee[1]}
                          options={store.neeOptions[0]}
                          disabled={disableInputs}
                          required
                          refresh={async (search, skip) => {
                            if (!store.directionSelected[0]) return undefined;
                            const options = await getNewLoadNeeNorDropdown("neenor", store.directionSelected[0], "consignee", search, skip, store.consigneeRPA[0]);

                            setNeeStandingAuthority(options.find((opt) => opt.id === store.consignee[0])?.standingAuthority)
                            return options;

                          }}
                          dependencies={[store.directionSelected[0]]}
                          onChangeAsObject={(opt) => setNeeStandingAuthority(opt.standingAuthority)}
                          info=
                          {
                            neeStandingAuthority ? <img src={checkbox} alt="" width="11px" height="9px" /> : <img src={blankCheckbox} alt=" " width="11px" height="9px" />

                          }

                        />
                        <div className='more-help' onClick={() => setShowNeeNor(true)} style={{ marginTop: '12px', color: "#00857d" }}>Add NeeNor?</div>
                      </section>

                      <section className="inputs-group">
                        <span className="section-title">Identification</span>
                        <Select
                          label="Shipping Status"
                          name="shipStatus"
                          data={store.shipStatus[0]}
                          setData={store.shipStatus[1]}
                          options={store.shippingStatusOptions[0]}
                          disabled={disableInputs}
                          required
                          info={
                            <OverlayTrigger
                              placement="top"
                              trigger={["hover", "focus"]}
                              overlay={
                                <Popover content>
                                  <h6>Accompanied</h6>
                                  <p>The driver travels with the shipment.</p>
                                  <h6>Unaccompanied</h6>
                                  <p>The shipment travels alone.</p>
                                </Popover>
                              }
                            >
                              <div className="info"></div>
                            </OverlayTrigger>
                          }
                        />
                        <Input
                          label="Truck / Trailer Number"
                          name="truck"
                          type="text"
                          value={store.truck[0]}
                          required
                          onChange={(e) => {
                            store.truck[1](e.target.value);
                            validateStringLength(e.target.value, "TruckNumber");
                          }}
                          error={validTruckNumberMessage}
                          disabled={disableInputs}
                          className="formattedText"
                        />
                        <Input
                          label="Trailer Seal Number"
                          name="trailerNo"
                          type="text"
                          value={store.trailerSeal[0]}
                          onChange={(e) => store.trailerSeal[1](e.target.value)}
                          disabled={disableInputs}
                          className="formattedText"
                        />
                        <Input
                          label="Date Of Loading"
                          name="date"
                          type="date"
                          value={store.date[0]}
                          required
                          onChange={(e) => store.date[1](e.target.value)}
                          disabled={disableInputs}
                        />
                        <Input
                          label="Container"
                          name="container"
                          type="text"
                          value={store.container[0]}
                          onChange={(e) => {
                            store.container[1](e.target.value);
                            validateStringLength(e.target.value, "Container");
                          }}
                          error={validContainerMessage}
                          disabled={disableInputs}
                          className="formattedText"
                          info={
                            <OverlayTrigger
                              placement="top"
                              trigger={["hover", "focus"]}
                              overlay={
                                <Popover style={{ minWidth: "30rem" }}>
                                  <PopoverContent>
                                    <h6>Container</h6>
                                    <p>Please provide your container number if known and arriving via Lo/Lo or Lift-on/Lift-off. </p>
                                    <p>If your container is arriving Ro/Ro or Roll-on/Roll-off on a skeletal trailer then you do not need to provide this.</p>
                                  </PopoverContent>
                                </Popover>
                              }
                            >
                              <div className="info"></div>
                            </OverlayTrigger>
                          }
                        />
                      </section>

                      <section className="inputs-group">
                        <span className="section-title">Commercial</span>
                        <Input
                          label="Job Reference"
                          name="jobref"
                          type="text"
                          required
                          classNameInputField={showRequiredFields ? 'loadInfo__required_fields' : ''}
                          value={store.jobRef[0]}
                          onChange={(e) => store.jobRef[1](e.target.value)}
                          disabled={disableInputs}
                          className="formattedText"
                        />

                        <Input
                          label="Invoice Number"
                          name="invoicenumber"
                          type="text"
                          required
                          classNameInputField={showRequiredFields ? 'loadInfo__required_fields' : ''}
                          value={store.invoicenumber[0]}
                          // onChange={(e) => store.invoicenumber[1](e.target.value)}
                          onChange={(e) => {
                            store.invoicenumber[1](e.target.value);
                            validateStringLength(e.target.value, "InvoiceNumber");
                          }}
                          error={validInvoiceNumberMessage}
                          disabled={disableInputs}
                          className="formattedText"
                        />

                        <Select
                          label="Invoice Currency"
                          name="invoicecurr"
                          data={store.invoicecurr[0]}
                          setData={updateCurrency}
                          options={store.currencyOptions[0]}
                          disabled={disableInputs}
                          required
                        />
                        <Select
                          label="Incoterm"
                          name="incotermcode"
                          data={store.selectincoterm[0]}
                          setData={updateIncoTerm}
                          options={store.incotermsOptions[0]}
                          disabled={disableInputs}
                          required
                          info={
                            <OverlayTrigger
                              placement="top"
                              trigger={["hover", "focus"]}
                              overlay={
                                <Popover style={{ minWidth: "30rem" }}>
                                  <PopoverContent>
                                    <h6>Incoterms</h6>
                                    <p>A set of internationally recognised rules which define the responsibilities of sellers and buyers. Incoterms specifies who is responsible for paying for and managing the shipment, insurance, documentation, customs clearance, and other logistical activities.</p>
                                  </PopoverContent>
                                </Popover>
                              }
                            >
                              <div className="info"></div>
                            </OverlayTrigger>
                          }
                        />

                        {isAdjustmentsInco &&

                          (<div className={`input-outer-container`}>
                            <label className="input-label" style={{ marginBottom: '0px' }}>
                              <button type="button" className="invoice-add-btn green-text" onClick={(e) => { setShowAdjustmentsModal(true) }}>
                                <span className="text-decoration-underline" style={{ fontSize: "0.9rem", fontStyle: 'italic' }}>
                                  Adjustments
                                </span>
                                <span>
                                  <OverlayTrigger
                                    placement="top"
                                    trigger={["hover", "focus"]}
                                    overlay={
                                      <Popover style={{ minWidth: "15rem" }}>
                                        <PopoverContent>
                                          {isAdjustmentsRequiredInco ? (
                                            <>
                                              <h6>Adjustments</h6>
                                              <p>Freight Charges is mandatory for this Incoterm.</p>
                                            </>
                                          ) : (
                                            <>
                                              <h6>Adjustments</h6>
                                              <p>Adjustments are optional for this Incoterm.</p>
                                            </>
                                          )}
                                        </PopoverContent>
                                      </Popover>
                                    }
                                  >
                                    <div className="info" style={{ marginBottom: '-5px' }}></div>
                                  </OverlayTrigger>
                                </span>
                              </button>

                            </label>
                          </div>)
                        }
                        {parseInt(store.selectincoterm[0]) === 7 && store.GBimport[0] &&
                          <Input
                            label="Incoterm Location"
                            name="incoLoco"
                            type="text"
                            value={store.incotermLocation[0]}
                            required={store.GBimport[0]}
                            onChange={(e) => store.incotermLocation[1](e.target.value)}
                            disabled={disableInputs}
                            className="formattedText"
                          />

                        }
                        {store.GBimport[0] && (
                          <>
                            <Select
                              label={store.RowImport[0] ? "Frontier Required" : "Controlled Goods"}
                              name="controlledgoods"
                              data={store.controlledGoods[0]}
                              setData={store.controlledGoods[1]}
                              options={yesNoOptions}
                              disabled={disableInputs}
                              required={!ffd}
                              onChange={(e) => store.controlledGoods[1](e.target.value)}
                              info={
                                <div className="important-info" onClick={() => setShowControlledGoodsModal(true)}></div>
                              }
                            />
                            <ControlledGoodsModal
                              header="Controlled Goods"
                              size="md"
                              showButtons={false}
                              animation={false}
                              show={showControlledGoodsModal}
                              onHide={() => setShowControlledGoodsModal(false)}
                              subTitle={'Select Yes in the drop-down if your Consignment contains any of these items - click on the information icons for more details.'}
                            >
                            </ControlledGoodsModal>
                          </>
                        )}

                        <NeeNorModal
                          onHide={() => setShowNeeNor(false)}
                          show={showNeeNor}
                          size="xl"
                          showButtons={false}
                          animation={false}
                          hideHeader={true}
                          isRow={(store.RowImport[0] || store.RowExport[0])}
                        >
                        </NeeNorModal>

                        <AdjustmentsModal
                          size='md'
                          show={showAdjustmentsModal}
                          onHide={() => { setShowAdjustmentsModal(false) }}
                          showButtons
                          ref={formRef}
                        />

                        <div className="flex-grow-1" />
                        <button
                          className="blue-button-newload"
                          type="submit"
                          style={{ justifySelf: "end" }}
                        >
                          Add Items
                        </button>
                      </section>

                      {store.details[0][0].Description}
                    </div>
                    <div></div>
                  </div>
                </form>
              </section>
            </Container>
          </>}
    </div>
  );

};

const isGBImport = id => {
  switch (id) {
    case 2: case '2': case 10: case '10': case 11: case '11': case 13: case '13':
      return true;
    default:
      return false;
  }
};

const isRowImport = id => {
  switch (id) {
    case 13: case '13':
      return true;
    default:
      return false;
  }
};

const isRowExport = id => {
  switch (id) {
    case 12: case '12':
      return true;
    default:
      return false;
  }
};

const isGBexport = id => {
  switch (id) {
    case 3: case 4: case 5: case 6: case 7: case 12:
    case '3': case '4': case '5': case '6': case '7': case '12':
      return true;
    default:
      return false;
  }
};

const isGBExportToROI = id => {
  switch (id) {
    case 6: case '6':
      return true;
    default:
      return false;
  }
};

export const isAdjustmentsRoute = id => {
  switch (id) {
    case 3: case 4: case 12:
    case '3': case '4': case '12':
      return false;
    default:
      return true;
  }
};

export default LoadInfo;