import React, { useContext, useState, useEffect } from 'react';
import { postAdditionalEPOFile, getEPO } from "../apiCalls";
import docs from "../Epo/assets/docupload.png";
import "../Newload/newLoad.css";
import { Trash } from "@styled-icons/fa-solid";
import "../Newload/newloadfileupload.css"
import { StoreContext } from "../Store";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const NewLoadOptions = () => {
  const [certsList, setCertsList] = useState([]);
  const { id } = useParams();
  const store = useContext(StoreContext);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [docsUploaded, setDocsUploded] = useState([])
  const [clickCount, setClickCount] = useState(0);
  const [newloadRef, setNewloadRef] = useState('EPO');

  useEffect(() => {
    const callEPODirection = async () => {
      const epoDiretion = await getEPO(id, 'direction')
      setNewloadRef(epoDiretion[0].TransferID)
      if (epoDiretion[0].isImport === true) {
        setCertsList([{ name: "Catch Certificate", checked: false },
        { name: "CITES Import Permit", checked: false },
        { name: "CITES Export Permit", checked: false },
        { name: "Export Health Certificate", checked: false },
        { name: "Import Licence", checked: false },
        { name: "IPAFFS", checked: false },
        { name: "Original Export Document", checked: false },
        { name: "Phytosanitary Certificate", checked: false },
        { name: "Commercial Document", checked: false }])
      }

      else {
        setCertsList([{ name: "Catch Certificate", checked: false },
        { name: "CITES Import Permit", checked: false },
        { name: "CITES Export Permit", checked: false },
        { name: "Export Health Certificate", checked: false },
        { name: "Export Licence", checked: false },
        { name: "CHED", checked: false },
        { name: "Phytosanitary Certificate", checked: false },
        { name: "Commercial Document", checked: false }])
      }
    }

    callEPODirection();

  }, [id]);

  function goBack() {
    navigate("/EpoListLoads")
  }

  const handleSubmit = async () => {
    const notTicked = certsList.reduce((acc, cert) => acc || (cert.checked), false);
    if (!notTicked) {
      setError("Please upload at least one document.");
      return
    } else {
      setClickCount(((preCount) => preCount + 1))
    }
    const formData = new FormData();
    for (const file of docsUploaded) {
      formData.append("documents", file.file);
      formData.append("documentNames", file.name);
    }
    formData.append('id', id);
    try {
      //setLoading(true);
      console.log('formdata here ', formData)
      await postAdditionalEPOFile(formData);
      navigate("/epolistloads");
      //setLoading(false);
    } catch (err) {
      console.log(err);
      store.infoText[1]("We could not process your file, please review the file and try again.")
      setError("We could not process your file, please review the file and try again.")
      //setLoading(false);        
    }
  };

  function addFile(file, certName) {

    if (file[0].name.slice(-3).toLowerCase() === "xls" || file[0].name.slice(-3).toLowerCase() === "pdf" ||
      file[0].name.slice(-3).toLowerCase() === "png" || file[0].name.slice(-3).toLowerCase() === "jpg" ||
      file[0].name.slice(-4).toLowerCase() === "jpeg" || file[0].name.slice(-4).toLowerCase() === "xlsx") {
      setDocsUploded([...docsUploaded, { name: certName, file: file[0] }]);
      setCertsList(certsList.map(cert =>
        cert.name === certName ? { ...cert, checked: true } : cert
      ));
    }
    else {
      setError("We could not process your file, please review the file and file type.")
    }
  }

  function removeFile(fileIdx, fileToRemove) {
    const typeCount = docsUploaded.filter(cert => cert.name === fileToRemove).length;
    const filteredData = docsUploaded.filter((file, index) => fileIdx !== index)
    setDocsUploded(filteredData)
    certsList.map(cert => {
      if (typeCount === 1 && cert.name === fileToRemove)
        cert.checked = false
      return cert;
    })
  }
  return (
    <div className="d-flex justify-content-center">
      <div
        className="bg-white d-flex flex-column justify-content-between pt-3"
        style={{
          maxWidth: "500px",
          marginTop: "2rem",
          padding: "2rem",
          borderRadius: "20px",
        }}
      >
        <span className="epo-steps-header">{`Job Ref: ${newloadRef}`}</span>
        <div className="step-banner img">
          <img src={docs} width="70" height="70" />
          <div className="d-flex flex-column" style={{ fontSize: "1.5rem" }}>
            <p>
              Please select any additional documents you wish to upload.
            </p>
            <span class="d-flex  flex-row italic" style={{ fontSize: '14px' }}>File types accepted are&nbsp;<span className="bold" style={{}}>.pdf, .png, .jpeg, .xls</span></span>
          </div>
        </div>

        <div className="d-flex flex-row justify-content-around" >

          <div className="d-flex flex-column gap-2" >

            <div>

              <div className="section-title" style={{ fontSize: '1rem', marginBottom: '0.3rem', fontWeight: "500" }}>Document Types</div>
              <div className="" style={{ marginBottom: "1.5rem" }}>
                {certsList.map((cert) => (
                  <label className="d-flex align-items-center gap-1">
                    <div className="d-flex gap-1 justify-content-between doc-upload-button" style={{ cursor: 'pointer' }}>

                      <label name="name" style={{ cursor: "pointer" }}>
                        <span
                          className="certificate"
                          style={{ fontSize: "1rem" }}
                        >
                          {cert.name}
                        </span>
                        <input
                          type="file"
                          name="fileuploader"
                          onChange={(e) => addFile(e.target.files, cert.name)}
                          onClick={(e) => { e.target.value = null; setError(""); }}
                          accept=".pdf,.png,.jpeg,.xls,.xlsx"
                        />
                      </label>


                      {cert.checked && (
                        <div className="tick-success-docs mt-1"></div>
                      )}
                    </div>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div
          className="d-flex flex-column gap-1 mt-1 mb-1 step-banner pd-2"
          style={{ minWidth: "220px", minHeight: "6rem", padding: "0.9rem" }}
        >
          <div
            className="section-title"
            style={{ fontSize: '14px', marginBottom: '0.3rem' }}
          >
            Uploaded...
          </div>
          {docsUploaded.map((document, fileIdx) => (
            <span
              key={`${document.name}.${fileIdx}`}
              className="d-inline-flex w-100 justify-content-between gap-3"
              style={{ fontSize: "1rem" }}
              title={document.name}
            >
              <span
                className="text-nowrap"
                style={{ textOverflow: "ellipsis", overflow: "hidden" }}
              >
                <span>{document.file.name}</span> -{" "}
                <span style={{ fontSize: "0.8rem" }}>{document.name}</span>
              </span>
              <button
                style={{
                  appearance: "none",
                  border: "none",
                  backgroundColor: "transparent",
                }}
                onClick={() => removeFile(fileIdx, document.name)}
              >
                <Trash
                  width={"1rem"}
                  height={"0.8rem"}
                  style={{ color: "#ff6464", marginBottom: "0.4rem" }}
                />
              </button>
            </span>
          ))}
        </div>
        <div className="px-3 mt-3 mb-3 w-100 mx-auto">
          {error && (
            <div className="alert-danger px-3 py-1 w-100 text-center" style={{ borderRadius: '10px' }}>{error}</div>
          )}
        </div>

        <section className="d-flex justify-content-between mt-2">
          <button type="button" className="stepper-button red" onClick={goBack}>
            Back
          </button>
          <button type="button" disabled={clickCount >= 1} className="stepper-button" onClick={handleSubmit}>
            Submit
          </button>
        </section>
      </div>
    </div>
  );
}

export default NewLoadOptions;