import { useContext, useEffect, useMemo, useState, useRef } from "react";
import { EpoJobDetailContext } from "../EpoJobDetails";
import DataTable from "../../../DataTable";
import { DeclarationsButton } from "../../../DeclarationsButton";
import { useDataTableHandle } from '../../../DataTable/DataTable';
import { useParams } from "react-router-dom";
import { getListLoad, postListLoad, postSDISubmit, postSFDSubmit, getChed, deleteChed, postEADSubmit, postC21Submit, postAdditionalEPOFile, updateTransactionLoadUploadedDocumentStatus } from "../../../apiCalls";
import { StoreContext } from "../../../Store";
import ArrivalConfirmation from '../../../ArrivalConfirmation';
import { ExternalLinkAlt, PlusCircle, Trash } from "@styled-icons/fa-solid";
import { useSecurity } from "../../../Security/security";
import { Modal } from "react-bootstrap";
import UploadIcon from "../../../../images/Upload.svg";
import "./epolistloads.css";

export default function EpoJobStatus() {
  const { jobDetails, canSave, isCustomer, showArrivalModal, setShowArrivalModal, setModalItem, modalItem, tab } = useContext(EpoJobDetailContext);
  // const [modalItem, setModalItem] = useState(null);
  // const [error, setError] = useState();
  const { id } = useParams();
  const store = useContext(StoreContext);
  const [requestFailed, setRequestFailed] = useState(false);
  const dataHandle = useDataTableHandle();
  const [error, setError] = useState();
  const { validateRole } = useSecurity();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [file, setFile] = useState(null);
  const warning = {
    title: "A Trader has already uploaded an IPAFFS.",
    body: (
      <>
        <p>
          To avoid duplication, please confirm you are not adding an IPAFFS reference submitted by the trader.
        </p>
      </>
    )
  };
  useEffect(() => {
    async function fetchData() {
      if (tab === 'status') {
        try {
          refreshGrid();
        } catch (err) {
          setRequestFailed(true);
        }
      }
      else return
    }
    fetchData();
  }, [tab])


  useEffect(() => {
    if (!store?.gridItems[0]) return;
    const declarationTypes = ["SDI", "SFD", "EAD"];
    declarationTypes.forEach((declarationType) => {
      const info = dataHandle.current.modifiedData.find((row) => row["Additional Information"] === declarationType);
      const value = info && info.formSubmitted ? info.formSubmitted : 0;
      store[`${declarationType.toLowerCase()}Submitted`][1](value);
    });
  }, [store?.gridItems, dataHandle?.current?.modifiedData]);

  const handleDocSubmit = async () => {
    const sfdRequest = {
      id: id
    };
    try {
      await postSFDSubmit(sfdRequest);
      refreshGrid();
    } catch (err) {
      console.log(err);
      alert("Error sending SFD");
    }
  }

  const handleC21Submit = async () => {
    const c21Request = {
      id: id
    };
    try {
      await postC21Submit(c21Request);
      refreshGrid();
    } catch (err) {
      console.log(err);
      alert("Error sending C21");
    }
  }

  const handleEADSubmit = async () => {
    const EADRequest = {
      id: id
    };
    try {
      await postEADSubmit(EADRequest);
      refreshGrid();
    } catch (err) {
      console.log(err);
      alert("Error sending EAD");
    }
  }

  const handleOpenModal = (item) => {
    setModalItem(item);
    setShowArrivalModal(true);
  }

  const createListLoadPayload = () => {
    const updatedCells = [];
    let i = 0;

    for (const row of dataHandle.current.modifiedData) {
      const system = row["System"];
      const refNo = row["System Ref No."];

      updatedCells.push({
        colname: "System",
        rowid: row.id,
        staterow: i++,
        value: system
      });

      updatedCells.push({
        colname: "SystemRefNo",
        rowid: row.id,
        staterow: i++,
        value: refNo
      });
    }
    const invLinked = dataHandle.current.modifiedData.find((row) => {
      return row["Form type"] === 'Inventory linked release'
    })
    if (invLinked) {
      updatedCells.push({
        colname: "PortArrived",
        rowid: invLinked.id,
        staterow: i++,
        value: (invLinked.PortArrived?.id ? invLinked.PortArrived.id : invLinked.PortArrived) ?? null
      })
    }

    postListLoadPayload(updatedCells)
  }

  const postListLoadPayload = async (payload) => {
    try {
      const data = await postListLoad(payload, 'status')
      if (data[0].completed === 'Updated') {
        store.saved[1](data);
        refreshGrid();
      }
    } catch (err) {
      console.log(err);
      alert('Error saving to DB')
    }
  }

  const handleSubmit = () => {
    setError();
    
    const sdi = dataHandle.current.modifiedData.find((row) => {
      return row["Additional Information"] === "SDI"
    });

    if (sdi) {
      if (sdi.Status === "Submitted" && !!sdi["System Ref No."]) {
        setError("Please request SDI (see button) before submitting a system reference number")
        return;
      }
    }

    const sfd = dataHandle.current.modifiedData.find((row) => {
      return row["Additional Information"] === "SFD"
    });
    
    if (sfd) {
      if (sfd.Status === "Submitted" && !!sfd["System Ref No."]) {
        setError("Please request an SFD (see button) before submitting MUCR/UCN reference number")
        return;
      }
    }

    createListLoadPayload();
  }

  const addRow = async () => {
    try {
      const data = await getChed(id);
      store.gridItems[1](data)
    } catch (err) {
      console.log(err);
      alert("Error adding ched");
    }
  }

  const removeRow = async (docId) => {
    try {
      const data = await deleteChed(docId);
      store.gridItems[1](data)
    } catch (err) {
      console.log(err);
      alert("Error deleting ched");
    }
  }

  const refreshGrid = async () => {
    try {
      const data = await getListLoad('status', id)
      //console.log('load', data, 'ID:', id);
      store.gridItems[1](data)
    } catch (err) {
      setRequestFailed(true);
    }
  }

  const handleModalSubmit = async () => {
    const sdiRequest = {
      id: id,
      date: store.portArrivalDate[0],
      time: store.portArrivalTime[0]
    };
    try {
      await postSDISubmit(sdiRequest);
      refreshGrid();
    } catch (err) {
      console.log(err);
      alert("Error sending SDI");
    }
  }

  const processFile = async (file, transactionID, transactionType) => {
    try {
      const formData = new FormData();
      formData.append('documents', file);
      formData.append("documentNames", transactionType);
      formData.append('id', id);

      const response = await postAdditionalEPOFile(formData);
      
      if (response.success) {
        const uploadedDocumentData = new FormData();
        uploadedDocumentData.append("id", transactionID);
        const response = await updateTransactionLoadUploadedDocumentStatus(transactionID);
        
        if (response[0].completed === 'Updated') {
          createListLoadPayload();
        }
      }
      else {
        console.error('response.error: ', response);
        setError(`The following error occurred while processing the file: ${response.error}`);
      }
      
    } catch (err) {
      console.error(err);
      setError('We could not process your file, please review the file and try again.')
    }
  }

  const uploadDocument = (e, transactionID, transactionType) => {
    const acceptedFiles = e.accept;
    const fileExtension = e.files[0]?.name?.slice(-4)?.toLowerCase();
    const isUploadedDocumentValid = acceptedFiles.includes(fileExtension);
    if (isUploadedDocumentValid) {
      processFile(e.files[0], transactionID, transactionType);
    }
  }

  const UploadDocumentStatus = () => {
    return (
      <span className="icon-md rounded-circle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon-sm"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    )
  }

  return (
    <div className="floating-box-newload loaditems">
      {showConfirmModal &&
        <WarningModal
          redirectReason={warning}
          onConfirm={
            () => {
              setShowConfirmModal(false);
              addRow();
              return null;
            }
          }
          onHide={() => {
            setShowConfirmModal(false);
            return null;
          }}
        />
      }
      <DataTable data={store.gridItems[0]} className="table-striped table-bordered" editable handle={dataHandle}>
        <DataTable.Headers className="bg-white" style={{ fontSize: '13px' }} />
        <DataTable.Column colKey="Form type" header="Transaction" />
        <DataTable.Column colKey="Country/Port" header="Location" />
        <DataTable.Column colKey="Additional Information" header="Additional Information" maxWidth='26ch' />
        <DataTable.Column colKey="System Ref No." header="System Reference" className='p-2 edit-cell system-reference' maxWidth='17ch' editable={(item) => {
          if (validateRole({ department: 'OPS' }) && item['Additional Information'] === "SDI" && item.Status === "In progress") {
            return item.SFD;
          } else if (validateRole({ department: 'OPS' }) && item['Additional Information'] === "SDI" && item.Status === "Completed") {
            return true;
          } else {
            return !item.locked;
          }
        }} />
        <DataTable.Column colKey="Status" header="Status" />
        <DataTable.Column header="Submit" style={{ whiteSpace: 'nowrap', width: '1px' }}>
          {(item) => {
            return (
              <DeclarationsButton
                C21={item.C21}
                SFD={item.SFD}
                SDI={item.SDI}
                EAD={item.EAD}
                CHED={item.CHED}
                ENS={item.ENS}
                TAD={item.TAD}
                InventoryLinked={item.InventoryLinked}
                Status={item.Status}
                formSubmitted={item.formSubmitted}
                handleOpenModal={() => handleOpenModal(item)}
                handleDocSubmit={() => handleDocSubmit(item)}
                handleEADSubmit={() => handleEADSubmit(item)}
                handleC21Submit={() => handleC21Submit(item)} />
            )}
          }
        </DataTable.Column>
        <DataTable.Column header="Docs" style={{ whiteSpace: 'nowrap', width: '4.5rem' }}>
          {(item) => {
            const elmID = `row-${item.id}-uploadedDocument`;
            return (
              <>
                <input type="hidden" name={elmID} value={!!item.uploadedDocument} />
                <span className="docs-upload-status">
                  <span className="uploadBtn">
                    <label name="name">
                      <img src={UploadIcon} alt="Click to upload document" title="Click to upload document" width="22" height="22" />
                      <input
                        type="file"
                        name="fileuploader"
                        onChange={(e) => uploadDocument(e.target, item?.id, item["Additional Information"])}
                        onClick={(e) => { e.target.value = null; setError(""); }}
                        accept=".pdf,.png,.jpeg,.xls,.xlsx"
                        multiple />
                    </label>
                  </span>
                  { item.uploadedDocument && <UploadDocumentStatus /> }
                </span>
              </>
            )}
          }
        </DataTable.Column>
        <DataTable.Column header="" style={{ height: '1px', width: '2rem' }} className="p-0">
          {(item) => {
            if ((item['Form type'] === "CHED (EU) / IPAFFS (GB)") && !item.formSubmitted) {
              return (
                <button className="invoice-delete-icon p-2 h-100" onClick={() => removeRow(item.id)}>
                  <Trash width="1rem" height="1rem" />
                </button>
              );
            } else {
              return null;
            }
          }}
        </DataTable.Column>
      </DataTable>

      <button type="button" className="invoice-add-btn green-text" onClick={() => setShowConfirmModal(true)}>
        <PlusCircle width="1.4rem" height="1.4rem" />
        <span className="text-decoration-underline bold" style={{ fontSize: '1rem' }}>
          Add CHED (EU) or IPAFFS (GB)
        </span>
      </button>

      <div className="d-flex flex-row py-3 px-3 mt-4 gap-3 border-top">
        <div className="flex-grow-1" />
        <div className="">
          {error && (
            <div className="alert-danger px-3 py-2 w-100 " style={{ borderRadius: '10px', textAlign: "center" }}>{error}</div>
          )}
        </div>
        <div className="flex-grow-1" />
        {/* <button className="stepper-button green" onClick={() => dataHandle.current.reset()}>Undo</button> */}
        <button className="stepper-button red" onClick={handleSubmit}>Update</button>

      </div>
      <ArrivalConfirmation
        header="Port Arrival"
        size="md"
        showButtons={true}
        animation={false}
        defaultDate={jobDetails.newArrivalDate ?? jobDetails.estimatedDateOfLoading}
        show={showArrivalModal}
        onHide={() => { setShowArrivalModal(false); setModalItem(null) }}
        subTitle={'Please select the port arrival date/time as this is required to produce a Supplementary Declaration.'}
        onConfirm={handleModalSubmit}
        item={modalItem}
      />
    </div>
  );
}

const WarningModal = ({ redirectReason, onHide, onConfirm }) => {
  return (
    <Modal show={!!redirectReason} centered size={"md"} onHide={onHide} animation={false}>
      <Modal.Header>
        <Modal.Title className="d-flex gap-3 align-items-center py-2">
          <div className="warning" />
          <span style={{ fontSize: '1.4rem' }}>
            {redirectReason?.title}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 py-4">
        {redirectReason?.body}
      </Modal.Body>
      <Modal.Footer
        className="d-flex flex-row py-3 px-3 gap-3 border-top bg-light justify-content-end align-items-center"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >
        <button
          id="cancel"
          className="cancel-button"
          onClick={onHide}>
          Cancel
        </button>
        <button
          id="confirm"
          className="blue-button-teams"
          onClick={(event) => {
            onConfirm();
            onHide(event);
          }}
          style={{ borderRadius: "10px" }}
        >
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
};