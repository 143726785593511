import React, { useState, useEffect, useContext, useMemo } from "react";
//import { withRouter } from "react-router-dom";
import { StoreContext } from "./Store";
import { useNavigate } from "react-router-dom";
import { Select, Input } from "../components/Input/Input";
import { getUsers, getUsersSearch, getCompanyList, updateUserStatus, updateDepartmentAndRole, updateRole, updateCompanyName, putUsersDownload } from "./apiCalls";
import { Table, Container, Row, Col, Button, Modal } from "react-bootstrap";
import  DataTable, {useDataTableHandle} from "./DataTable";
import { ExternalLinkAlt, Pen } from "@styled-icons/fa-solid";
import { useSecurity } from "./Security/security";
import DownloadIcon from "../images/download.svg";
import isoDateConverter from "./isoDateConverter";
import ConfirmationModal from "./ConfirmationModal";
import * as XLSX from 'xlsx';

const BulkEditBar = ({users, departmentRoles, fetchUsers}) => {
  const [operation, setOperation] = useState('unlock')
  const operations = [
    {id: 'unlock', data: 'Unlock'},
    {id: 'lock', data: 'Lock'},
    {id: 'enable', data: 'Enable'},
    {id: 'passwordReset', data: 'Password Reset'},
    {id: 'changeRole', data: 'Change Access'},
    {id: 'changeDeptAndRole', data: 'Change Department and Access'}
  ]
  const navigate = useNavigate();

  const [role, setRole] = useState('Standard');
  const [roleOptions, setRoleOptions] = useState([]);

  const [department, setDepartment] = useState('')

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const userDepartments = useMemo(() => {
    return Array.from(new Set(users.map((usr) => usr.department)))
  }, [users])

  const departmentOptions = useMemo(() => {
    return Array.from(new Set(departmentRoles.map((dr) => dr.department))).map((dept) => ({
      id: dept,
      data: dept.toUpperCase()
    }))
  }, [departmentRoles])

  const confirmTitle = useMemo(() => {
    if (operation === 'unlock') return `You are about to unlock ${users.length} users.`
    if (operation === 'lock') return `You are about to lock ${users.length} users.`
    if (operation === 'enable') return `You are about to enable ${users.length} users.`
    if (operation === 'passwordReset') return `You are about to reset the password for ${users.length} users.`
    if (operation === 'changeRole') return `You are about to change ${users.length} users to ${role}.`
    if (operation === 'changeDeptAndRole') return `You are about to change ${users.length} users to ${department} - ${role}.`
  }, [operation, users, department, role])

  useEffect(() => {
    if (operation === 'changeRole') {
      setRoleOptions(departmentRoles
        .filter((dr) => dr.department.toUpperCase() === (userDepartments[0] ?? '').toUpperCase())
        .map((dr) => ({id: dr.role, data: dr.role.toUpperCase()})
        ));
    }
    else if (operation === 'changeDeptAndRole') {
      setRoleOptions(departmentRoles
        .filter((dr) => dr.department.toUpperCase() === department.toUpperCase())
        .map((dr) => ({id: dr.role, data: dr.role.toUpperCase()})
        ));
    }
  }, [operation, department, users])

  const handleApply = async () => {
    if (operation === 'unlock') {
      await updateUserStatus('unlock', { userIds: users.map((u) => ({id: u.id})) })
    }
    else if (operation === 'lock') {
      await updateUserStatus('lock', { userIds: users.map((u) => ({id: u.id})) })
    }
    else if (operation === 'enable') {
        await updateUserStatus('enable', { userIds: users.map((u) => ({id: u.id})) })
    }
    else if (operation === 'passwordReset') {
      await updateUserStatus('passwordReset', { userIds: users.map((u) => ({id: u.id, username: u.userName})) })
    }
    else if (operation === 'changeRole') {
      await updateRole(users.map((u) => ({id: u.id})), role);
    }
    else if (operation === 'changeDeptAndRole') {
      await updateDepartmentAndRole(users.map((u) => ({id: u.id})), department, role);
    }
    fetchUsers();
  }

  const applyDisabled = useMemo(() => {
    if (operation === 'changeRole' && userDepartments.length > 1) return true
    else if (operation === 'changeRole') return role === ''
    else if (operation === 'changeDeptAndRole') return role === '' || department === ''
    else return false
  }, [operation, role, department, userDepartments])

  if (users.length === 0) return null;
  return (
    <>
          <ConfirmationModal
      header={confirmTitle} 
      show={showConfirmModal}
      onHide={(event) => {
        if((event?.target?.id !== "confirm") ){         
          fetchUsers();
        }
          setShowConfirmModal(false);        
      }}
      onConfirm={handleApply}
      hideCancel={false}
      animation={true}
      >
        </ConfirmationModal>

        <div style={{backgroundColor: '#eee', width: '100%'}}>
      <div className="p-2 d-flex flex-row flex-start">
        <div style={{paddingTop: '30px', paddingRight: '30px'}}>
          {users.length} Users Selected
        </div>
        <div style={{paddingRight: '30px'}}>
                    <Select
                    label={'Operation'}
                    data={operation}
                    setData={(val) => setOperation(val)}
                    options={operations}
                    />
                </div>
          {operation === 'changeDeptAndRole' && 
              <div style={{paddingRight: '30px'}}>
              <Select
              label={'Department'}
              data={department}
              setData={(val) => setDepartment(val)}
              options={departmentOptions}
              />
          </div>
            
          }
          {(operation === 'changeDeptAndRole' || operation === 'changeRole') &&
            <>
            {operation === 'changeRole' && userDepartments.length > 1 ? 
            <div style={{paddingRight: '30px', paddingTop: '30px'}}>
            All selected users must belong to same department to change access level.
            </div>
            :
            <div style={{paddingRight: '30px'}}>
            <Select
            label={'Role'}
            data={role}
            setData={(val) => setRole(val)}
            options={roleOptions}
            />
        </div>
    
          }
            </>

        }

        <button style={{marginTop: '4px'}} type="button" className="blue-button"
        onClick={() => setShowConfirmModal(true)}
        disabled={applyDisabled}>Apply</button>
      </div>
    </div>
    </>
  )
}

const UserAdmin = () => {
  const [users, setUsers] = useState([]);
  const [requestFailed, setRequestFailed] = useState(false);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [departmentRoles, setDepartmentRoles] = useState([])
  const { validateRole } = useSecurity();
  const [companyList, setCompanyList] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [modalFor, setModalFor] = useState("");
  const [companyNameTo, setCompanyNameTo] = useState("");
  const [showCompanyNameModal, setShowCompanyNameModal] = useState(false);
  const [companyNameError, setCompanyNameError] = useState("");

  const dth = useDataTableHandle();

  const navigate = useNavigate();
  const store = useContext(StoreContext);

  const minWidthValue = validateRole({department: 'IT'}) ? '1500px' : '';

  useEffect(()=>{
    if(validateRole({department: 'IT'})){
      if(store?.userIdSelected[0] !== 0){
        fetchData(store?.userIdSelected[0]); 
      }
    }  
  },[store?.userIdSelected[0]])

  const fetchData = async (id) => {    
    try {
        const data = await getUsers("search",id);
        setUsers(data);
    } catch (err) {
      console.log(err);
      setRequestFailed(true);
    }
  };

  const fetchUsers = async()=>{
    const companyId = sessionStorage.getItem('companyid');
    store.userIdSelected[1](parseInt(companyId));
    try {
      const data = await getUsers("search",companyId);
      setUsers(data);
    } catch (err) {
      console.log(err);
      setRequestFailed(true);
    }
  }

  useEffect(() => {
    if(!validateRole({department: 'IT'})){
      fetchUsers();
    }
  }, []);

  const fetchCompanyList = async () => {
    try {
      const data = await getCompanyList();
      const newData = data?.map(({ id, company, EORI }) => ({ id, data: company, eori: EORI }));
      const result = newData.find(({ eori }) => eori === "GB269573944000");
      if (result) {
        store?.userIdSelected[1](result.id)
      }

      setCompanyList(newData);   
    } catch (err) {
      console.log(err);
      setRequestFailed(true);
    }
  };

  function setState(event) {
    const companyId = event.target.value;
    dth.current.resetPagination()
    if(companyId){
      store.userIdSelected[1](parseInt(companyId))
      //fetchData(companyId);
    }


  }
  
  const handleSubmit = async (body) => {
    const data = await getUsersSearch(body);
    setUsers(await data);
  }

  const handleConfirm = async ()=>{
    console.log("after confirm",users)
    //conditionlly select ids for unlock or enable
  }

   const handleUtilities = () =>{
    setShowConfirmModal(true);
    setConfirmTitle("Utilities");
    setModalFor("Utilities");
    fetchData(store?.userIdSelected[0]); 
   }

  const handleSelect = (id) => {
    setUsers(prev => prev.map((user) => {
      if (user.id === id) return {...user, selected: !user.selected}
      return user
    }))
  };

  const selectedUsers = useMemo(() => {
    return users.filter((user) => user.selected)
  }, [users])

  useEffect(() => {
    if (validateRole({ department: "IT" })) {
      fetchCompanyList();
    }
  }, []);

  const getCompanyName = () =>{  
    const foundCompany = companyList.find(company => parseInt(company.id) === parseInt(store?.userIdSelected[0]));
    return foundCompany ? foundCompany.data : null;
  }

  const downloadUserDataAsExcel = async() =>{
    const data = await putUsersDownload("download", store?.userIdSelected[0])
        const workbook = XLSX.utils.book_new();
        // Convert JSON data to worksheet
        const worksheet = XLSX.utils.json_to_sheet(data);
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        // Convert the workbook to a binary string
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
        // Convert the binary string to a Blob
        const blob = new Blob([bs2Blob(wbout)], { type: 'application/octet-stream' });
        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        const currentDate = isoDateConverter(new Date())
        
        // downloadLink.download = 'userData.xlsx';
        downloadLink.download = data[0].company + '_' + currentDate + '.xlsx';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        // Clean up
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(downloadLink.href);
  } 

  const bs2Blob = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  const renderContent = () => {
    if (modalFor === "Utilities") {
      return (
        <div style={{display: "flex", flexDirection:"column", textAlign: "center"}}>
          <div>Company name: {getCompanyName()}</div>
          <div style={{marginTop:"16px"}}>
            <button
              type="button"
              className="stepper-button yellow"
              style={{ width: "150px" }}
              onClick={downloadUserDataAsExcel}
            >
              <span>
                Download <img src={DownloadIcon} width="22" height="22" />
              </span>
            </button>
          </div>
        </div>
      );
    }
  };

   useEffect(() => {
    const fetchRoles = async () => {
       const depsAndRoles =(await getUsers("departmentsAndRolesList", null))
       setDepartmentRoles(depsAndRoles);
       let deps = Array.from(new Set(depsAndRoles.map((dr) => dr.department)));
       deps = deps.map(dep => ({id: dep, data: dep.toUpperCase()}));
       setDepartmentOptions(deps);
     }
     fetchRoles();
   }, [])

  if (requestFailed) return <p>Failed!</p>;
  return (
    <Container className="teamBackground" style = {{ minWidth: minWidthValue }}>
      {showConfirmModal && 
      <ConfirmationModal
      header={confirmTitle} 
      show={showConfirmModal}
      onHide={(event) => {
        if((event?.target?.id !== "confirm") && (modalFor !== "Utilities") ){         
          fetchData(store.userIdSelected[0]);
        }
          setShowConfirmModal(false);        
      }}
      onConfirm={handleConfirm}
      hideCancel={modalFor === "Utilities" ? true : false}
      animation={true}
      >
        {renderContent()}
      </ConfirmationModal>
      
      }
            <ConfirmationModal
      header={"Change Company Name"} 
      show={showCompanyNameModal}
      onHide={async (event) => {
        if((event?.target?.id === "confirm") ){         
          if (companyNameTo.length > 0) {
            setShowCompanyNameModal(false);  
            await updateCompanyName(store?.userIdSelected[0], companyNameTo)
            fetchCompanyList()
          } else {
            setCompanyNameError("Company Name must be at least 1 character long")
          }
          }
          else {
            setShowCompanyNameModal(false);
          }
      
      }}
      onConfirm={handleConfirm}
      animation={true}
      >
        <p>Changing from name: {companyList.find((c) => c.id === store?.userIdSelected[0])?.data}</p>
        <Input label="To" 
            type='text' 
            name='companyNameTo' 
            id='companyNameTo' 
            value={companyNameTo} 
            onChange={e => setCompanyNameTo(e.target.value)} 
            error={companyNameError}
            />
      </ConfirmationModal>


      {/* Unsure where the below classname comes from, doesn't seem to effect code */}
      <Row className="background ">
        <Col className="teamHeader ">
          <div className="d-flex justify-content-between">
            <span>User Admin</span>
            {validateRole({department: 'IT'}) && <div style={{display: "flex", maxWidth: "300px", justifyContent: "center"}}>
        <Select
         label="Company List"
         name="companyList"
         data = {store?.userIdSelected[0]}
         onChange={setState}
         required
         options={companyList}
        />
        <Pen width="25px" style={{marginTop: '10px', marginLeft: '10px', ...(store.userIdSelected[0] ? {cursor: 'pointer'} : {cursor: 'not-allowed'})}} title="Edit Company Name"
        onClick={() => {
          if (store?.userIdSelected[0]) {
            setShowCompanyNameModal(true);
            setCompanyNameError("");
            setCompanyNameTo(companyList.find((c) => c.id === store?.userIdSelected[0])?.data);
          }
        }}
        />
        </div>}
        {!validateRole({department: 'IT'}) &&  <button className="blue-button-teams green-btn text-decoration-none" onClick={() => navigate('/UserDetails/new')}>
              Add
            </button>}

          </div>
        </Col>

        {/* Class created for table to match margins with form */}
        
        <Row className="adminTable"> 
        { validateRole({department: 'IT'}) &&  
        <BulkEditBar users={selectedUsers} departmentRoles={departmentRoles} 
        fetchUsers={() => fetchData(store?.userIdSelected[0])}/>     
    }
          <DataTable data={users} handle={dth} sortable filterable dontRefreshPagination className="table-striped table-bordered">
            <Button>Submit</Button>
            <DataTable.Pagination itemsPerPage={[10, 25, 50]} style={{fontSize:"4px"}} />
            <DataTable.Headers style={{backgroundColor: 'white',fontSize: '13px'}} />

            { validateRole({department: 'IT'}) && 
            <DataTable.Column colKey="selected" maxWidth={60} header="Select" style={{textAlign:"center"}}>
              {({id, selected})=>{                                    
                return (( <label><input type='checkbox' checked={selected} onChange={(e) => {handleSelect(id)} }/></label>))
              }}
            </DataTable.Column>}
            <DataTable.Column colKey="userName" header="Username"  searchOpts={{type: "text", sendAs: "userName"}} />
            <DataTable.Column colKey="firstname" header="First Name" searchOpts={{type: "text", sendAs: "firstName"}}/>
            <DataTable.Column colKey="lastname" header="Last Name" searchOpts={{type: "text", sendAs: "lastName"}} />
            <DataTable.Column colKey="department" header="Dept" searchOpts={{type: "select", src: departmentOptions, sendAs: "department"}}/>            
            { validateRole({department: 'IT'}) && <DataTable.Column colKey="role" header="Access" searchOpts={{type: "text", src: departmentOptions, sendAs: "role"}}/>}
          
            { validateRole({department: 'IT'}) && <DataTable.Column colKey="lockedout" maxWidth={60} header="Locked" style={{textAlign:"center"}}/>}
            { validateRole({department: 'IT'}) &&  <DataTable.Column colKey="disabled" maxWidth={60} header="Disabled" style={{textAlign:"center"}}/>}
           { validateRole({department: 'IT'}) && <DataTable.Column foldable colKey="lastLoggedIn" header="Last Login" style={{minWidth:"135px"}}>
            {({lastLoggedIn})=> lastLoggedIn && (isoDateConverter(lastLoggedIn))}
            </DataTable.Column>}
            { validateRole({department: 'IT'}) && <DataTable.Column foldable colKey="passwordLastChanged" header="PWD Changed" style={{textAlign:"center", minWidth:"150px"}}>
            {({passwordLastChanged})=> isoDateConverter(passwordLastChanged)}
            </DataTable.Column>}
            <DataTable.Column colKey="admin" maxWidth={60} header="Admin" style={{textAlign:"center"}} searchOpts={{type: "select", src: [
              {id: 1, data: "Yes"},
              {id: 0, data: "No"}
            ], sendAs: "admin"}} />
             { validateRole({department: 'IT'}) && <DataTable.Column colKey="deleted" maxWidth={60} header="Deleted" style={{textAlign:"center"}}/>}
           
            <DataTable.Column className="p-0" style={{ height: "1px" }} header="View">
            {({ id }) => (
                <button
                  className="external-table-link"
                  onClick={() =>
                    navigate({
                      pathname: `/UserDetails/${id}`
                    })
                  }
                >
                  <ExternalLinkAlt width="1rem" height="1rem" />
                </button>
              )}
            </DataTable.Column>
          </DataTable>
        </Row>
      </Row>
      { validateRole({department: 'IT'}) && (<section className="py-3 px-3 mt-4 gap-3 border-top" style={{display:"flex"}}>
      <div className="gap-3" style={{display:"flex"}}>
      <button type="button" className="stepper-button" onClick={()=>{navigate('/UserDetails/new')}}>
          Add
        </button>
       <button type="button" disabled = { (store?.userIdSelected[0] === 0) ? true : false} className="stepper-button yellow" onClick={handleUtilities} >
        Utilities
        </button>
        </div>
      </section>)}
    </Container>
  );
};

export default UserAdmin;




