import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import DataTable from '../../DataTable';

import { applyCommodityCodesMapping, checkNewCommodityCodesMapping } from "../../apiClass_commoddity_codes";

const ManageCommodityCodesModal = ({ showModal, productList, spredsheetProductRows, hsCodeMaxAllowedLength, customerID, customerName, customerEORINumber, setProductsForReviewInModal, onHide }) => {
  const [disableApplyChangesBtn, setDisableApplyChangesBtn] = useState(false);
  const [commodityCodeMappingValidationMessage, setCommodityCodeMappingValidationMessage] = useState('');
  
  const checkCommodityCode = async (id, field) => {
    const value = field.value;
    field.removeAttribute('data-error');
    setDisableApplyChangesBtn(false);
    setCommodityCodeMappingValidationMessage('');

    if (value === '') {
      productList?.forEach((product) => {
        if (product?.id === id) {
          product.commodityCode = null;
          product.companyId = null;
        }
      });
      field.removeAttribute('data-error');
      field.value = value;
      setDisableApplyChangesBtn(false);
    }
    else {
      if (value.length === hsCodeMaxAllowedLength) {
        try {
          const response = await checkNewCommodityCodesMapping(value);

          if (response) {
            const commcodeValid = response[0].commcodeValid;
            const commcodeError = response[0].commcodeError;

            if (commcodeValid) {
              productList?.forEach((product) => {
                if (product?.id === id) {
                  product.commodityCode = value;
                  product.companyId = customerID;
                }
              });
              field.removeAttribute('data-error');
              setDisableApplyChangesBtn(false);
            }
            else {
              productList?.forEach((product) => {
                if (product?.id === id) {
                  product.commodityCode = null;
                  product.companyId = null;
                }
              });
              setCommodityCodeMappingValidationMessage(commcodeError);
              field.setAttribute('data-error', 'true');
              field.value = value;
              setDisableApplyChangesBtn(true);
            }
          }
        } catch (err) {
          console.error(err);
          field.removeAttribute('data-error');
          setDisableApplyChangesBtn(false);
        }
      }
      else {
        field.setAttribute('data-error', 'true');
        field.value = value;
        productList?.forEach((product) => {
          if (product?.id === id) {
            product.commodityCode = null;
            product.companyId = null;
          }
        });
        setDisableApplyChangesBtn(true);
      }
    }
  }

  const applyChanges = async () => {
    const productListForPayload = productList?.filter(product => product?.commodityCode)
      .map(product => ({
        id: product.id,
        companyId: product.companyId,
        description: product.description,
        hsCode: product.hsCode,
        commodityCode: product.commodityCode
      }));

    if (productListForPayload.length > 0) {
      try {
        const payload = { data: productListForPayload };
        const response = await applyCommodityCodesMapping(payload);

        if (response) {
          response.forEach(product => {
            const replaceProductCommodityCode = product.id !== null && product.valid;
            if (replaceProductCommodityCode) {
              //when we have a product match, we update the HSCode for all matching products in the list
              spredsheetProductRows?.forEach((item) => {
                if (item[0]?.text.trim() === product.hsCode.trim() && item[2]?.text.trim() === product.description.trim()) {
                  item[0].text = product?.commodityCode;
                }
              });
            }
          });
          setProductsForReviewInModal();
          onHide();
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const showApplyNewMappingsBtn = productList.length > 0;

  const customerNameAndNumber = customerName() !== '' && customerEORINumber() !== '' ? `${customerName()} (${customerEORINumber()})` : '-';

  return (
    <Modal centered size={'lg'} className='manage-commodity-codes-modal' show={showModal} onHide={onHide}>
      <Modal.Header className="floating-box-header-newload">
        <Modal.Title>Manage Commodity Codes</Modal.Title>
        <button
          id="close"
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={onHide}
        />
      </Modal.Header>
      <Modal.Body>

        <div className='customer-heading'>
          <span className='label'>Customer:</span>
          <span className='value'>{customerNameAndNumber}</span>
        </div>
        {
          productList.length ? (
            <DataTable data={productList} className="table-striped">
              <DataTable.Headers style={{ fontSize: "14.4px", paddingBottom: '10px', borderBottom: '1px solid #ddd', backgroundColor: '#fff', borderTop: '1px solid #ddd' }} />
              <DataTable.Column className='hsCode' colKey="hsCode" header="HS Code" searchOpts={{ type: "text", sendAs: "eori" }} />
              <DataTable.Column className='description' colKey="description" header="Description" searchOpts={{ type: "text", sendAs: "description" }} />
              <DataTable.Column className='commodityCode' colKey="commodityCode" header="Map to GB HS Code" searchOpts={{ type: "text", sendAs: "commodityCode" }}>
                {
                  (item) => {
                    return (
                      <input
                        type="number"
                        name={`product-${item.id}-commodityCode`}
                        className="input-element"
                        onBlur={(e) => checkCommodityCode(item.id, e.target)}
                      />
                    )
                  }
                }
              </DataTable.Column>
            </DataTable>
          ) : (
            <p className="user-notification">All product commodity code for {customerName() !== '' ? customerName() : 'this customer'} succesfully replaced.</p>
          )
        }
      </Modal.Body>
      <Modal.Footer>
        <Button className="epo-continue-button" onClick={onHide}>
          Close
        </Button>
        {
          showApplyNewMappingsBtn && <div className="apply-changes-section">
            {commodityCodeMappingValidationMessage && <p className="error-message">{commodityCodeMappingValidationMessage}</p>}
            <Button disabled={disableApplyChangesBtn} className="blue-button-newload" onClick={applyChanges}>
              Apply
            </Button>
          </div>
        }
      </Modal.Footer>
    </Modal>
  );
};

export default ManageCommodityCodesModal;