import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { DocViewer } from "../DocViewers";
import { getEPO } from "../../../apiCalls";

function DocViewerModal({ invoice, onClose }) {
    const [localInvoiceData, setLocalInvoiceData] = useState();
    const [fileType, setFileType] = useState();
    const [fileBuffer, setFileBuffer] = useState();

    useEffect(() => {
        setLocalInvoiceData(invoice);
        if (invoice?.invoiceId) {
            getEPO(invoice.invoiceId, "Invoice").then((res) => {
                setFileBuffer(Buffer.from(res[0].invoice.data));
                setFileType(invoice.filename.split('.').slice(-1)[0].toLowerCase());
            });
        } else {
            setFileBuffer();
            setFileType();
        }
    }, [invoice]);

    if (!localInvoiceData?.invoiceId) return null;

    return (
        <Modal show={localInvoiceData?.invoiceId} centered contentClassName="editModal" dialogClassName="superwide-modal">
            <Modal.Header>
                <Modal.Title>Viewing: {localInvoiceData?.filename}</Modal.Title> <button type="button" class="btn-close" aria-label="Close" onClick={onClose} />
            </Modal.Header>
            <div className="flex-grow-1 d-flex flex-column">
                <Modal.Body className="flex-grow-1">
                    <DocViewer fileBuffer={fileBuffer} filetype={fileType} />
                </Modal.Body>
                <Modal.Footer
                    class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light align-items-center"
                    style={{ borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}
                >
                    <div className="d-flex gap-2 align-items-center">
                        <span className="bold" style={{ fontSize: '16px' }}>Totals:</span>
                        <span className="badge bg-success" style={{ fontSize: '14px' }}>Gross Mass: {localInvoiceData.invGrossMass ?? 0}kg</span>
                        <span className="badge bg-success" style={{ fontSize: '14px' }}>Net Mass: {localInvoiceData.invNetMass ?? 0}kg</span>
                        <span className="badge bg-success" style={{ fontSize: '14px' }}>Value: {(localInvoiceData.invValue ?? 0).toFixed(2)}</span>
                        <span className="badge bg-success" style={{ fontSize: '14px' }}># Rows: {localInvoiceData.itemRows ?? 0}</span>
                    </div>
                    <div className="flex-grow-1" />
                    <button type="button" class="cancel-button" onClick={onClose} style={{ display: "flex", borderRadius: "10px" }}>
                        Cancel
                    </button>
                </Modal.Footer>
            </div>
        </Modal>
    );
}

export default DocViewerModal;