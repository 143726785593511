import _ from "lodash";

import React, { useState, useEffect, useRef, useCallback, useReducer } from "react";
import "./Spreadsheet.css";

import Keyboard from "./Keyboard";
import Mouse from "./Mouse";
import RightClickMenu from "./RightClickMenu";
import TheTable, { TableSetup } from "./Table";
import FloatingRect from './FloatingRect'
import Functions from "./Functions";

import { excelReducer } from "./reducer";



export function useSpreadsheetHandle() {
    const ref = useRef({
      [Symbol.for("SpreadsheetHandleRef")]: true,
      excelState: {},
      excelDispatch: () => null
    });
    return ref;
  }
/**
 * Correctly gets the position bounding rectangle from the top-left of the document.
 * (as the floating rects are absolutely position)
 */
const getBoundingClientOffset = (element) => {
  let bodyRect = document.body.getBoundingClientRect(),
    elemRect = element.getBoundingClientRect(),
    y = elemRect.y - bodyRect.y,
    x = elemRect.x - bodyRect.x,
    right = elemRect.right - bodyRect.x,
    bottom = elemRect.bottom - bodyRect.y;

  return { x, y, right, bottom };
};

export const CallbackContext = React.createContext();

const Spreadsheet = (props) => {
  const { visible, editable } = props;
  const [selection, setSelection] = useState({
    root: { x_left: 0, x_right: 0, y_top: 0, y_bottom: 0, row_idx: 0, col_idx: 0 },
    bounding: { x_left: 0, x_right: 0, y_top: 0, y_bottom: 0, row_idx: 0, col_idx: 0 },
  });
  const [isEditing, setIsEditing] = useState(false);
  const [temporaryText, setTemporaryText] = useState("");
  const commodity_table_ref = useRef(null);
  const edit_input_el = useRef(null);

  const [handlePaste, setHandlePaste] = useState(() => () => null)
  const [handleMouseDown, setHandleMouseDown] = useState(() => () => null)
  const [lastRowClicked, setLastRowClicked] = useState(() => () => null)

  const [startEditing, setStartEditing] = useState(() => () => null)
  const [stopEditing, setStopEditing] = useState(() => () => null)
  const [clearSelection, setClearSelection] = useState(() => () => null)
  const [getSelectionAsText, setGetSelectionAsText] = useState(() => () => null)

  const DEFAULT_ROWS = 0;

  const [excelState, excelDispatch] = useReducer(excelReducer, {
    rows: [],
    undoStack: [],
    config: [],
    emptyRow: [],
    files: [],
    recalc: 0,
    onUpdate: null,
    defaultRows: DEFAULT_ROWS
  });

  useEffect(() => {
    if (props.handle) {
      if (!props.handle?.current?.[Symbol.for("SpreadsheetHandleRef")]) {
        throw new Error("`handle` prop must be created using `useSpreadsheetHandle`.");
      }

      if (props.handle?.current) {
        props.handle.current.excelState = excelState
        props.handle.current.excelDispatch = excelDispatch
        props.handle.current.registered = true
      }
    }
  }, [props.handle, excelState, excelDispatch]);
 

  /** Window events */
  const handleResize = useCallback(
    _.debounce(
      () => {
        excelDispatch({ type: "recalculate" });
      },
      250,
      { leading: true, trailing: true }
    ),
    []
  );

  /**
   * Binds window resize to retrigger bounding box calculations.
   */
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  

  useEffect(() => {
    if (isEditing) {
      edit_input_el.current.focus();
    } else {
      commodity_table_ref.current.focus();
    }
  }, [isEditing]);

  const callbackContext = {
    lastRowClicked,
    handleMouseDown,
    excelDispatch,
    excelState,
    startEditing,
    setStartEditing,
    stopEditing,
    setStopEditing,
    selection, 
    setSelection,
    isEditing,
    setIsEditing,
    stopEditing,
    clearSelection,
    setClearSelection,
    excelDispatch,
    editable,
    handlePaste,
    setHandlePaste,
    getSelectionAsText,
    setGetSelectionAsText,
    handleMouseDown,
    setHandleMouseDown,
    lastRowClicked,
    setLastRowClicked,
    getBoundingClientOffset,
    setTemporaryText,
    temporaryText
  };
  return (
    <CallbackContext.Provider value={callbackContext}>
      <Keyboard ref={commodity_table_ref}>
        <Mouse ref={commodity_table_ref} />
        <Functions />
        {visible && (
          <>
            <RightClickMenu
              tableElement={commodity_table_ref.current}
              onPaste={handlePaste}
              onCopy={getSelectionAsText}
              onCut={() => {
                const text = getSelectionAsText();
                clearSelection();
                return text;
              }}
              onUndo={() => excelDispatch({ type: "undo" })}
            />
            <FloatingRect
              className="root-overlay"
              x_left={selection.root.x_left}
              x_right={selection.root.x_right}
              y_top={selection.root.y_top}
              y_bottom={selection.root.y_bottom}
              error={selection.root.error}
              ref={commodity_table_ref}
            >
              {isEditing && (
                <input
                  type={excelState.config[selection.root.col_idx]?.type}
                  style={{
                    textAlign: excelState.config[selection.root.col_idx]?.align,
                  }}
                  ref={edit_input_el}
                  value={temporaryText}
                  placeholder={temporaryText}
                  onChange={(e) => setTemporaryText(e.target.value)}
                  onBlur={() => stopEditing(true)}
                />
              )}
            </FloatingRect>
            <FloatingRect
              className="selection-overlay"
              x_left={selection.bounding.x_left}
              x_right={selection.bounding.x_right}
              y_top={selection.bounding.y_top}
              y_bottom={selection.bounding.y_bottom}
              ref={commodity_table_ref}
            />
          </>
        )}

        <TableSetup defaultRows={props.defaultRows ?? DEFAULT_ROWS}>
          {props.children}
        </TableSetup>
        <TheTable />
      </Keyboard>
    </CallbackContext.Provider>
  );
};



function Column() {
  return null;
}

Column.displayName = "Column"
Spreadsheet.Column = Column



export default Spreadsheet;
