import { fetch } from './apiCalls';

const urlForManageCommCode = process.env.REACT_APP_API + 'manageCommCode';
const urlForValidateCommCode = urlForManageCommCode + '/validate';
const urlForManageNewLoadCommCode = process.env.REACT_APP_API + 'newload/manageCommCode';


export const postCommodityCodesLazyLoad = async (data) => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`,
      'Content-Type': 'application/json'
    }
  
    const response = await fetch(`${urlForManageCommCode}/all`, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(data)
    });
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'));
    } else {
      return await response.json();
    }
  }
  
  export const getCommodityCodeDetails = async (id) => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`
    }
    const response = await fetch(`${urlForManageCommCode}?id=${id}`, {
      method: 'GET',
      headers: header
    });
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'));
    } else {
      return await response.json();
    }
  }
  
  export const checkCommodityCodeValid = async (data) => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`,
      'Content-Type': 'application/json'
    }
  
     const response = await fetch(`${urlForManageNewLoadCommCode}`, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(data)
    });
    
   if (response.status >= 400) {
      throw (new Error('Network request failed'));
    } else {
      return await response.json();
    }
  }
  
  export const updateCommodityCode = async (data) => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`,
      'Content-Type': 'application/json'
    }
  
    const response = await fetch(`${urlForManageCommCode}`, {
      method: 'PUT',
      headers: header,
      body: JSON.stringify(data)
    });
    
    if (response.status >= 400) {
      throw (new Error('Network request failed'));
    } else {
      return await response.json();
    }
  }
  
  export const applyCommodityCodesMapping = async (data) => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`,
      'Content-Type': 'application/json'
    }
  
    const response = await fetch(`${urlForManageCommCode}`, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(data)
    });
    

    if (response.status >= 400) {
      throw (new Error('Network request failed'));
    } else {
      return await response.json();
    }
  }
  
  export const checkNewCommodityCodesMapping = async (commodityCode) => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`
    }
  
    const response = await fetch(`${urlForValidateCommCode}?commodityCode=${commodityCode}`, {
      method: 'GET',
      headers: header
    });
    
    if (response.status >= 400) {
      throw (new Error('Network request failed'));
    } else {
      return await response.json();
    }
  }