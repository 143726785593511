import DataTable, {useDataTableHandle} from "../DataTable";
import { ExternalLinkAlt} from "@styled-icons/fa-solid";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect, useState, useContext, useMemo } from "react";
import { getEPOStatuses, searchEpoListLazy, putArrivalDate, getEpoForm } from "../apiCalls";
import { useSecurity } from "../Security/security";
import { StoreContext } from "../Store";
import { useNavigate } from "react-router-dom";
import UploadIcon from "../../images/Upload.svg";
import DownloadIcon from "../../images/download.svg";
import copyIcon from "../../images/clipart.png";  
import eta from "../../images/eta.png";
import clickIcon from "../../images/Search.svg";
import { UpdateEtaModal } from "./UpdateEtaModal";
import DocRefModal from "./DocRefModal";
import { PDFDownloadLink } from "@react-pdf/renderer";
import JobReceipt from "../JobReceipt";
import FormatDate from "../Util/FormatDate";
import {Popover, PopoverContent, OverlayTrigger} from "react-bootstrap";
import { set } from "lodash";

const EpoListLoads = () => {
  const [priorityJobs, setPriorityJobs] = useState([]);
  const [showUpdateEta, setShowUpdateEta] = useState(false);
  const [updateEtaRecord, setUpdateEtaRecord] = useState({});
  const [showDocRef, setShowDocRef] = useState(false);
  const { validateRole } = useSecurity();
  const [statuses, setStatuses] = useState([]);
  const [role, setRole] = useState(false);
  const [modalData, setModalData] = useState([])
  const [paginationLoaded, setPaginationLoaded] = useState(false);
  const [requestFailed, setRequestFailed] = useState(false);
  const store = useContext(StoreContext);
  const navigate = useNavigate();
  const dth = useDataTableHandle();
  const [dashboard, setDashboard] = useState(null)
  const [priority, setPriority] = useState(null);
  const [arrived, setArrived] = useState(null);

  useEffect(() => {   
    setRole(['LSP', 'TRADER', 'WLP'].some(dep => validateRole({ department: dep})));
    //Reset from previous epo job
    store.eadSubmitted[1](0);
    store.sfdSubmitted[1](0);
    store.sdiSubmitted[1](0);

    // When the component mounts, set the state of dashboard, priority and arrived to the query parameters in the url. If the parameters are not present, set the state to an empty string
    const urlParams = new URLSearchParams(window.location.search);
    setDashboard(urlParams.get('dashboard') !== null ? urlParams.get('dashboard') : "");
    setPriority(urlParams.get('priority') !== null ? urlParams.get('priority') : "");
    setArrived(urlParams.get('arrived') !== null ? urlParams.get('arrived') : "");

}, []);

  useEffect(() => {
    if (paginationLoaded && dashboard !== null && priority !== null && arrived !== null){ //make sure that we have the values for dashboard, priority and arrived before fetching data
      fetchData();
    }
  }, [dashboard, paginationLoaded, priority, arrived]);

  async function fetchData() {
    try {
      //console.log('pagination is ', dth.current.pagination)
      const data = await searchEpoListLazy({
        skip: dth.current.pagination.pageNo * dth.current.pagination.itemsPerPage, 
        amount: dth.current.pagination.itemsPerPage,
        sort_by: dth.current.sortStatus.currentKey,
        sort_order: dth.current.sortStatus.direction,
        filter: ( dth.current.filterStatus?.filterText ? { [dth.current.filterStatus.filterByKey] : dth.current.filterStatus.filterText} : {}
        ),
        dashboardElement: dashboard,
        priority: priority,
        arrived: arrived
      });
      dth.current.setServerDataLength(data.totalItems)
      setPriorityJobs(data.data);
      setStatuses((await getEPOStatuses()).map(status => ({id: status.id, data: status.status})));
    } catch (err) {
      setRequestFailed(true);
    }
  }

  async function updateData(setPaginationLength, pagination, sortStatus, search, filter) {
    //CABS-1729 - force order of 'Completed' EPO Loads in 'Descending' order
    const showCompletedEpoJobs = filter?.epoStatus?.toLowerCase() === 'completed';
    const sortStatusKey = showCompletedEpoJobs && sortStatus.currentKey === undefined ? "newArrivalDate" : sortStatus.currentKey;
    const sortStatusDirection = showCompletedEpoJobs && sortStatus.direction === undefined ? "desc" : sortStatus.direction;

    const payload = {
      skip: pagination.pageNo * pagination.itemsPerPage, 
      amount: pagination.itemsPerPage,
      sort_by: sortStatusKey,
      sort_order: sortStatusDirection,
      search,
      filter,
      dashboardElement: dashboard,
      priority: priority,
      arrived: arrived
    };

    try {
      const data = await searchEpoListLazy(payload);
      setPaginationLength(data.totalItems);
      setPriorityJobs(data.data);
    }
    catch (err) {
      setRequestFailed(true);
    }
  }

  const handleReset = () => {
    setDashboard("");
    setPriority("");
    setArrived("");
  }

  const fetchDocsPreference = async(id)=>{
      try {
        const data = await getEpoForm("forms", id);
        setModalData(data);
      } catch (err) {
        console.log("fetch froms",err);
        setRequestFailed(true);
      }
  }

  const  handleDocsRefPopup = (e,item)=>{
    e.preventDefault();
    fetchDocsPreference(item.newLoadId);
    setShowDocRef(true)
  }

  // useEffect(() => {
  //   console.log('paginationloaded', paginationLoaded) //code will be inserted here
  //   if (paginationLoaded){
  //       fetchData();
  //   }
  // }, [paginationLoaded]);

  function gotoDocumentUploadPage(id, transferId, isImport) {
    store.newLoadID[1](id);    
    store.epoJobRef[1](transferId);
    store.epoImport[1](isImport);
    navigate(`/AdditionalEPOFileUpload/${id}`)
    //console.log(id);
  }

  function gotoDownloadPage(id) {
    store.newLoadID[1](id);
    // console.log(store.newLoadID[1](id))
    navigate('/EPODownloads')
  }

  const computeClassName = (value) => {
    switch (value) {
      case 1:
      case 2:
        return "bg-danger text-uppercase";
      default:
        return "bg-lightblue text-uppercase";
    }
  };
  

  const handleUpdateArrivalDate = async ({ date }) => {
    await putArrivalDate({
      id: updateEtaRecord.newLoadId,
      newArrivalDate: date
    });
    updateEtaRecord.newArrivalDate = date;
  }


  const refreshTable = function() {
    return 1000 - (new Date().getTime() % 1000);
  }

  const showDocsStatus = (formStatus, type)=>{
    switch (type) {
      case "CHED":
      case "TAD":
      case "EAD":
      case "ENS":
        if (formStatus === "1") return <div className="tick-success cursor-normal"></div>;
        break;
      case "SDI":
      case "SFD":
            if (formStatus === "Completed") return <div className="tick-success cursor-normal"></div>;
        break;
      default:
        return null;
    }
  }

  const startTime = useMemo(() => {
    return new Date();
  }, [])

  if (requestFailed) return <p>Failed!</p>;

  return (
    <>
      <UpdateEtaModal newArrivalDate={FormatDate(updateEtaRecord.newArrivalDate, '-', true)} 
      estimatedDateOfLoading={FormatDate(updateEtaRecord.estimatedDateOfLoading, '-', true)}
      show={showUpdateEta} 
      onHide={() => setShowUpdateEta(false)} 
      onConfirm={handleUpdateArrivalDate} 
      isImport ={updateEtaRecord?.isImport}/>
      <DocRefModal  data={modalData} show={showDocRef}  onHide={() => setShowDocRef(false)} />
      <div className="full-width-container">
        <section className="floating-box" style={{ maxWidth: "1530px" }}>
          <div className="floating-box-header-general">
            <span>Express Loads</span>
          </div>
          <div className="px-4 pt-4">
            <div>
            <DataTable dontRefreshPagination idKey="newLoadId" handle={dth} data={priorityJobs} 
            className="table-striped" sortable filterable lazyLoading
              advancedSearch onUpdate={updateData} 
              refreshTable={refreshTable} 
              showAdvnSearch={!validateRole({department: 'TRADER'}) && !validateRole({department: 'LSP'}) && !validateRole({department: 'WLP'})}
              onPaginationLoaded={() => {
                setPaginationLoaded(true);
              }}
              persistGetter={store.EPOPersist[0]}
              persistSetter={store.EPOPersist[1]}
              handleReset={handleReset}
              >
              <DataTable.Headers className=" px-2" style={{fontSize: '12.4px',  backgroundColor: '#fff', paddingBottom: '10px', borderBottom: '1px solid #ddd', borderTop: '1px solid #ddd'}} />
              <DataTable.Pagination itemsPerPage={[10, 25, 50]} className="w-100 d-flex justify-content-end" />
              <DataTable.Row style={{fontSize: '14px'}} />
              <DataTable.Column colKey="route" header="Route"/>
              <DataTable.Column colKey="transferId" header="CABIE Ref"  searchOpts={{type: "text", sendAs: "refno"}} />
              <DataTable.Column colKey="company" header="Trader" />
              { (!validateRole({department: 'TRADER'})) && (!validateRole({department: 'LSP'})) && (!validateRole({department: 'WLP'})) &&
              <DataTable.Column colKey="originator" header="Created By" />
              }
              <DataTable.Column colKey="jobRef" header="Trader Ref" style={{maxWidth: "150px"}} searchOpts={{type: "text", sendAs: "jobReference"}}>
              {({jobRef}) => {
                return (
                  <div className="d-flex align-items-center justify-content-between">
                    {jobRef?.length > 20 ? `${jobRef?.slice(0, 20)}` : jobRef}
                    {(jobRef?.length > 20) ? <RefNoTooltip jobRef = {jobRef}/> : null}
                  </div>
                );
              }}              
              </DataTable.Column>
              <DataTable.Column colKey="newArrivalDate" header="Arrival / Exit">
                {(item) => {
                  return (
                    <div
                    style={{ cursor: item.epoStatus === "Completed" ? "not-allowed" : "pointer" }}
                    onClick={(e) => {                     
                      e.preventDefault();
                      setUpdateEtaRecord(item)
                      if (item.epoStatus !== "Completed"){
                        setShowUpdateEta(true);
                      }
                    }}
                  >
                    {FormatDate(item.newArrivalDate)}
                    <img src={eta} style={{width: '18px', height: '18px', marginLeft: '5px'}}/>
                  </div>
                    )
                }}
              </DataTable.Column>
              <DataTable.Column foldable colKey="submittedBy" header="Created by" searchOpts={{type: "select",  src: "data", sendAs: "submitter"}} />
              <DataTable.Column colKey="epoStatus" header="Status" searchOpts={{type: "select", src: statuses, sendAs: "status"}}>
              {({ epoStatus }) => {
                  return (
                    <div>
                      {epoStatus === 'Waiting for Customer' ? (
                          <div>{epoStatus}<span className="warning" style={{marginLeft: '9px', height: '15px'}}></span></div>
                      ) : (
                        epoStatus
                      )}
                    </div>
                  );
                }}
              </DataTable.Column>

              <DataTable.Column foldable colKey="lastModifiedBy" header="Updated by" />
              <DataTable.Column foldable colKey="lastModifiedDate" header="Updated">
                {({lastModifiedDate}) => (
                  new Date(lastModifiedDate).toLocaleString()
                )}
              </DataTable.Column>

              { (!validateRole({department: 'TRADER'})) && (!validateRole({department: 'LSP'})) && (!validateRole({department: 'WLP'})) &&
              <DataTable.Column header="SLA" colKey="createdDate">
              {({ createdDate, Priority, epoStatus }) => {
                const createdDateTime = new Date(createdDate);
                const SLA = createdDateTime.setHours(createdDateTime.getHours()+2)
                let timeDelta;
                if (epoStatus === "Waiting for Customer")
                  timeDelta = SLA - startTime;
                else
                  timeDelta = SLA - new Date();
                let timeDisplay;
                let redClass;

                  let milliseconds = Math.floor((timeDelta % 1000) / 100),
                  seconds = Math.floor((timeDelta / 1000) % 60),
                  minutes = Math.floor((timeDelta / (1000 * 60)) % 60),
                  hours = Math.floor((timeDelta / (1000 * 60 * 60)));

                  if (Math.abs(hours) > 23) {
                    const days = Math.floor(Math.abs(hours)/24);
                    timeDisplay = days + " days"
                    redClass = 'clock-red'
                  }
                  else {
                  redClass = ""
                  if (hours < 1 && minutes <= 30)
                    redClass = "clock-red"
                  let negative=""
                  if (hours < 0 || minutes < 0 || seconds < 0) negative = "-"
                  //Negative values always showing one hour extra, so adjusted it
                  hours = (hours < 0) ? Math.abs(hours) - 1 : Math.abs(hours); 
                  minutes = (minutes < 0) ? Math.abs(minutes) - 1 : Math.abs(minutes); 
                  seconds = (seconds < 0 ) ? Math.abs(seconds) - 1 : Math.abs(seconds);
                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  

                  timeDisplay = negative + hours + ":" + minutes + ":" + seconds
                }

                if (Priority === 1 || Priority === 2)
                return (
                  <div className={"clock " + redClass} style={{ marginLeft: "1rem" }}>
                    {timeDisplay}
                  </div>
                )
                else return null
              }}
              </DataTable.Column>
            }

              { (!validateRole({department: 'TRADER'})) && (!validateRole({department: 'LSP'})) && (!validateRole({department: 'WLP'})) &&
                  <DataTable.Column colKey="Priority" header="Type" searchOpts={{type: "select", src: [{id: 0, data: "Standard"}, {id:1, data: "Priority"}, {id:2, data: "Priority (TAD)"}], sendAs: "priority"}}>
                 {({ Priority }) => (
                      <Badge className={computeClassName(Priority)}>
                        {Priority === 0 ? 'Standard' : Priority === 1 ? 'Priority' : 'Priority (TAD)'}
                      </Badge>
                  )}
                </DataTable.Column>
              }
            
            {/* { (!validateRole({department: 'WLP'}))  && */}
            {/* <div> */}
              <DataTable.Column foldable  header="UPLOAD" className="p-0" style={{ height: "1px" }} headerStyle={{textAlign: 'center'}}>
              {({newLoadId, transferId, isImport}) => (
                <button className="external-table-link" onClick={() => gotoDocumentUploadPage(newLoadId, transferId, isImport)}>
                  {/* <Paperclip width="1rem" height="1rem" strokeWidth={'1px'} /> */}
                  <img src={UploadIcon} width="22" height="22" />
                </button>
              )}
            </DataTable.Column>
            
            <DataTable.Column foldable header="DOWNLOAD" className="p-0" style={{ height: "1px" }} headerStyle={{textAlign: 'center'}}>
              {({newLoadId}) => (
                <button className="external-table-link" onClick={() => gotoDownloadPage(newLoadId)}>
                  {/* <Paperclip width="1rem" height="1rem" strokeWidth={'1px'} /> */}
                  <img src={DownloadIcon} width="22" height="22" />
                </button>
              )}

            </DataTable.Column>
            {role && <DataTable.Column foldable header="RECEIPT" className="p-0" style={{ height: "1px" }} headerStyle={{textAlign: 'center'}}>
              {(epoLoad) =>{
                       let newEpoLoad;
                       if (epoLoad.isImport) {
                         newEpoLoad = {
                          ...epoLoad,
                          controlledGoods: (epoLoad?.confirmedNoCG === "N") ? false : true,
                          port: epoLoad.entryPort, 
                          euPrefSelected: (epoLoad?.exportAreaCode === 'EU') ? true: false
                        };
                       } else {
                         newEpoLoad = {
                           ...epoLoad,
                           port: epoLoad.exitport,
                           controlledGoods: (epoLoad?.confirmedNoCG === "N") ? false : true,
                           sealNo: epoLoad.SealNumber,
                           inlandTransId: epoLoad.InlandTransportID,
                           officeDest: epoLoad.officeOfDest,
                         };
                       }                       
                return(
                        <PDFDownloadLink
                        document={<JobReceipt jobDetails={newEpoLoad} id = "listloads" />}
                        fileName={"confirmation.pdf"}
                        className="invoice-add-btn green-text fw-bolder"
                        style={{ fontSize: "16px" }}
                      >
                        {(
                            <button className="external-table-link">
                               <img src={DownloadIcon} width="22" height="22" />                              
                            </button>
                          )
                        }
                      </PDFDownloadLink>
              )}}
            </DataTable.Column>}

            {/* <DataTable.Column foldable header="GB SDI" colKey="SDI" className="p-0" style={{ height: "1px" }} headerStyle={{textAlign: 'center'}}>
              {(item) => !item.isImport? "N/A" : showDocsStatus(item?.SDI, "SDI")}
            </DataTable.Column>
            <DataTable.Column foldable header="GB SFD" colKey="SFD" className="p-0" style={{ height: "1px" }} headerStyle={{textAlign: 'center'}}>
              {(item) => !item.isImport? "N/A" : showDocsStatus(item?.SFD, "SFD") }
            </DataTable.Column>
            <DataTable.Column foldable header="ENS" colKey="ensSubmitted" className="p-0" style={{ height: "1px" }} headerStyle={{textAlign: 'center'}}>
            {(item) => item.isImport? "N/A" : showDocsStatus(item?.ensSubmitted, "ENS")}
          </DataTable.Column>
          <DataTable.Column foldable header="EAD" colKey="eadSubmitted" className="p-0" style={{ height: "1px" }} headerStyle={{textAlign: 'center'}}>
          {(item) =>  item.isImport? "N/A" : showDocsStatus(item?.eadSubmitted, "EAD")}
          </DataTable.Column>
          <DataTable.Column foldable header="TAD" colKey="tadSubmitted" className="p-0" style={{ height: "1px" }} headerStyle={{textAlign: 'center'}}>
          {(item) => item.isImport ? "N/A" :showDocsStatus(item?.tadSubmitted, "TAD")}
          </DataTable.Column>
          <DataTable.Column foldable header="CHED" colKey="chedSubmitted" className="p-0" style={{ height: "1px" }} headerStyle={{textAlign: 'center'}}>
          {(item) => item.isImport ? "N/A" : showDocsStatus(item?.chedSubmitted, "CHED")}
          </DataTable.Column> */}

          { (!validateRole({department: 'TRADER'})) && (!validateRole({department: 'LSP'})) && (!validateRole({department: 'WLP'})) &&
              <DataTable.Column header="View" style={{height: '1px', padding: 0}} headerStyle={{textAlign: 'center'}}>
              {(item) =>{                
                     return (
                       <Link
                         className="external-table-link"
                         to={`/EpoJobDetails/${item.newLoadId}`}
                       >
                         <ExternalLinkAlt width="0.8rem" height="0.8rem" />
                       </Link>
                     );                
              }}
             </DataTable.Column>
            }
         { (validateRole({department: 'TRADER'}) || validateRole({department: 'LSP'}) || validateRole({department: 'WLP'})) && 
              <DataTable.Column header="Doc Refs" style={{height: '1px', padding: 0}} headerStyle={{textAlign: 'center'}}>
              {(item) => {
                  return (
                    <Link
                      className={(item.isImport === true && item.confirmedNoCG === "N" || item.epoStatus !== "New") ?  "external-table-link" : "external-table-link-disabled"}
                      onClick={(e) => (item.isImport === true && item.confirmedNoCG === "N" || item.epoStatus !== "New") && handleDocsRefPopup(e, item)}
                    >
                      <ExternalLinkAlt width="0.8rem" height="0.8rem" />
                    </Link>
                  );
              }}
             </DataTable.Column>
            }
            </DataTable>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default EpoListLoads;

export const RefNoTooltip = ({jobRef}) => {
  const [isCopied, setIsCopied] = useState(false);
  const popoverWidth = `${jobRef.length * 15}px`; 

  const copyToClipboard = async() => {    
    try {
      await navigator.clipboard.writeText(jobRef); 
      setIsCopied(true);     
    } catch (err) {
      setIsCopied(false); 
      console('Failed to copy!');
    }  

    setTimeout(() => {
      setIsCopied(false);
    }, 100);
  }

  return (
    <OverlayTrigger
      placement="right"
      trigger={["click"]}
      rootClose
      overlay={
        <Popover style={{ maxWidth: popoverWidth, padding: "6px" }}>
        <PopoverContent>
          <div className="d-flex ">
            <p className={isCopied ? "copied-text" : ""}>{jobRef}</p>
            <div style={{ marginLeft: "13px" }}>
              <img
                src={copyIcon}
                alt={'Full Reference'}
                style={{
                  cursor: "pointer",
                  width: "19px",
                  height: "19px",
                  marginBottom: '15px'
                }}
                onClick={(e) => copyToClipboard()}
              />
            </div>
          </div>
        </PopoverContent>
      </Popover>
      
      }
    >
      <img
        src={clickIcon}
        alt={'Copy to clipboard'}
        style={{
          cursor: "pointer",
          width: "14px",
          height: "14px"
        }}
      />
    </OverlayTrigger>
  );
}
