import { useCallback, useContext, useEffect, useState, useMemo, useRef } from "react";
import { EpoJobDetailContext } from "../EpoJobDetails";
import { Input, Select, NeeNorSelect } from "../../../Input/Input";
import { Col, OverlayTrigger, Popover, PopoverContent, Row } from "react-bootstrap";
import { StoreContext } from "../../../Store";
import { getNewLoad, getNewLoadDropdown, getNewLoadNeeNorDropdown, getNewLoadNeeDropdown, getEPO, getDestCountryOptions, updateEPOStatus } from "../../../apiCalls";
import { ShowIf } from "../../../Security/security";
import { LongArrowAltRight } from "@styled-icons/fa-solid";
import { EPOAdjustmentsModal } from '../../../EPOAdjustmentsModal'
import { useParams } from 'react-router-dom';

export default function EpoJobHeaders({ editInvoiceData }) {
  const { jobDetails, setJobDetails, updateJobDetails, canSave, isCustomer, setShowUpdateEta, adjustmentsIncoLoaded, setAdjustmentsIncoLoaded, showAdjustmentsModal, setShowAdjustmentsModal, setIsFreightChargesInco, formRef, tab, setServerData } = useContext(EpoJobDetailContext);
  const store = useContext(StoreContext);
  const adjustmentsRef = useRef();
  const [euToGB, setEuToGB] = useState(null);
  const isImport = jobDetails?.isImport
  const { id } = useParams();

  useEffect(() => {

    async function fetch() {
      const movementTypes = await getNewLoad("movement");
      const euToGBLocal = movementTypes.find(movement => movement.data = "EU (mainland) - GB")
      setEuToGB(euToGBLocal);
      getNewLoad("currency").then((data) => { store.currencyOptions[1](data); });
      getNewLoad("incoterm").then((data) => { store.incotermsOptions[1](data); });
      const countryOptions = await getDestCountryOptions(isImport, parseInt(id));
      store.dualCountryOptions[1](countryOptions)
    }
    if (typeof isImport === 'boolean') {
      fetch();
    }
  }, [adjustmentsIncoLoaded, isImport, id]);


  const updateAdjustments = (returnData) => {
    adjustmentsRef.current = [
      {
        name: "freightCharges",
        display: "Freight Charges",
        amount: returnData.freightCharges ?? "",
        currency: store.currencyOptions[0].find((item) => item.id === returnData.freightChargescurrency?.toString())?.id ?? "",
        required: false
      },
      {
        name: "insuranceCharges",
        display: "Insurance Charges",
        amount: returnData.insuranceCharges ?? "",
        currency: store.currencyOptions[0].find((item) => item.id === returnData.insuranceChargescurrency?.toString())?.id ?? "",
        required: false
      },
      {
        name: "vatAdjustment",
        display: "VAT Adjustment",
        amount: returnData.vatAdjustment ?? "",
        currency: store.currencyOptions[0].find((item) => item.id === returnData.vatAdjustmentcurrency?.toString())?.id ?? "",
        required: false
      },
      {
        name: "otherCharges",
        display: "Other Additions",
        amount: returnData.otherCharges ?? "",
        currency: store.currencyOptions[0].find((item) => item.id === returnData.otherChargescurrency?.toString())?.id ?? "",
        required: false
      },
      {
        name: "invoiceDiscount",
        display: "Other Deductions",
        amount: returnData.invoiceDiscount ?? "",
        currency: store.currencyOptions[0].find((item) => item.id === returnData.invoiceDiscountcurrency?.toString())?.id ?? "",
        required: false
      }
    ]
  }

  const isAdjustmentsInco = useMemo(() => {
    // if (store.selectincoterm[0].length === 0 || store.selectincoterm[0] >= 7)
    if (!jobDetails.incoterm || (parseInt(jobDetails.incoterm) > 6 && parseInt(jobDetails.incoterm) < 11))
      return false;
    return true;
  }, [jobDetails.incoterm])

  const isAdjustmentsRequiredInco = useMemo(() => {
    return store.incotermOptionsRequireAdjustmentsValues[0].includes(jobDetails.incoterm?.toString())
  }, [jobDetails.incoterm])

  useEffect(() => {
    if (adjustmentsIncoLoaded && store.currencyOptions[0].length > 0) {
      updateAdjustments(jobDetails)
      updateAdjustmentsFromIncoTerm();
      setAdjustmentsIncoLoaded(false);
    }
  }, [jobDetails.incoterm, store.currencyOptions[0], adjustmentsIncoLoaded])

  const updateIncoTerm = (data) => {
    updateJobDetails('incoterm', data);
    updateAdjustmentsFromIncoTerm(data);
  }

  const updateAdjustmentsFromIncoTerm = (data) => {
    const incoterm = data?.toString() ?? jobDetails.incoterm?.toString()
    if (adjustmentsRef.current) {
      updateJobDetails('adjustments', adjustmentsRef.current.map((adjustment) => {
        if (adjustment.name === 'freightCharges')
          adjustment.required = (store.incotermOptionsRequireAdjustmentsValues[0].includes(incoterm))
        return adjustment
      }))
      adjustmentsRef.current = null;
    }
    else {
      updateJobDetails('adjustments', jobDetails.adjustments.map((adjustment) => {
        if (adjustment.name === 'freightCharges')
          adjustment.required = (store.incotermOptionsRequireAdjustmentsValues[0].includes(incoterm))
        return adjustment
      }))
    }
    if (jobDetails.isImport) {
      setIsFreightChargesInco(store.incotermOptionsRequireAdjustmentsValues[0].includes(incoterm))
    }
  }
  const displayCompany = () => {
    const { consignorName, compName } = jobDetails;
    return (
      <Col>
        <Input
          disabled
          label={"Company Name"}
          value={isImport ? compName : consignorName}
        />
      </Col>
    );
  };

  const displayPort = () => {
    const { exitPort, entryPort } = jobDetails;
    return (
      <Col>
        <Input
          disabled
          label={isImport ? "Entry Port" : "GB Port of Exit"}
          value={isImport ? entryPort : exitPort}
        />
      </Col>
    );
  };


  const displayNeeNorCode = () => {
    const consignorLabel = isImport ? "Consignor Code" : "Consignee Code";
    const name = isImport ? "Consignor" : "Consignee";
    const isDisabled = (!canSave || isCustomer || !editInvoiceData);
    return (
      <NeeNorSelect
        label={consignorLabel}
        name={name}
        disabled={isDisabled}
        data={jobDetails.consignor}
        setData={(data) => updateJobDetails('consignor', data)}
        refresh={
          async (search, skip) => {
            if (typeof jobDetails.newLoadId === 'number') {
              return await getNewLoadNeeDropdown("neenor", jobDetails.newLoadId, name, search, skip);
            }
          }
        }
        dependencies={[euToGB]}
        dropdownWidth="720px"
      />
    );
  };

  const updateStatus = async () => {
    await updateEPOStatus(jobDetails.id)

    const data = await getEPO(id, "Header");
    setJobDetails({ id, ...data.Parents[0], });
    setServerData({ id, ...data.Parents[0] });
  }


  return (
    <div>
      <div className="registrationText mb-2">Company Information</div>
      <Row>

        {displayCompany()}

        <Col>
          <Input disabled label="EORI Number" value={jobDetails.EORI} />
        </Col>
        <Col>
          <Input disabled label="Originator" value={jobDetails.originator} />
        </Col>
        <Col>
          <Input disabled label="Submitter" value={jobDetails.submittedBy} />
        </Col>
      </Row>

      <div className="registrationText my-2">Submitted Data</div>
      <Row>
        {displayPort()}
        <Col>
          <ShowIf isCustomer>
            <div
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setShowUpdateEta(true);
              }}
            >
              <Input
                type="date"
                label={jobDetails.isImport ? "Date of Arrival" : "Date of Exit"}
                value={jobDetails.newArrivalDate ?? jobDetails.estimatedDateOfLoading}
                info={<span className="green-text text-decoration-underline bold">Amend</span>}
              />
            </div>
          </ShowIf>
          <ShowIf isCustomer={false}>
            <Input
              type="date"
              label={jobDetails.isImport ? "Date of Arrival" : "Date of Exit"}
              value={jobDetails.newArrivalDate ?? jobDetails.estimatedDateOfLoading}
              disabled
              info={
                !!jobDetails.newArrivalDate && (
                  <OverlayTrigger
                    placement="right"
                    trigger={["hover", "focus"]}
                    overlay={
                      <Popover style={{ minWidth: "20rem" }}>
                        <PopoverContent>
                          <h6>Arrival Date Amended</h6>
                          <p>The trader has changed their arrival date since first creating this consignment.</p>
                          <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ fontSize: '0.8rem' }}
                          >
                            <div className="text-center">
                              <header className="fw-bolder text-eoriblue">Original Arrival Date</header>
                              <div className="fw-bolder red-text">{jobDetails?.estimatedDateOfLoading}</div>
                            </div>

                            <LongArrowAltRight width="1.5em" height="1.5em" />

                            <div className="text-center">
                              <header className="fw-bolder text-eoriblue">New Arrival Date</header>
                              <div className="fw-bolder green-text">{jobDetails.newArrivalDate}</div>
                            </div>
                          </div>
                        </PopoverContent>
                      </Popover>
                    }
                  >
                    <div className="warning"></div>
                  </OverlayTrigger>
                )
              }
            />
          </ShowIf>
        </Col>

        <Col>
          <Input label="Job Reference" value={jobDetails.jobReference} className="formattedText" disabled />
        </Col>
      </Row>
      <div className="registrationText my-2">Invoice Data</div>
      <Row>
        <Col>
          <Select
            label={jobDetails.isImport ? "Country of Despatch" : "Destination Country"}
            name="countrydespatch"
            // disabled={!canSave || isCustomer || !editInvoiceData || (typeof jobDetails.despatchCountry === 'number')}
            disabled={!canSave || isCustomer || !editInvoiceData}
            data={jobDetails.despatchCountry}
            setData={(data) => {
              updateJobDetails('despatchCountry', data);
            }}

            required
            options={store.dualCountryOptions[0]}
          />
        </Col>
        <Col>
          {displayNeeNorCode()}
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            label="Invoice Currency"
            // disabled={!canSave || isCustomer || !editInvoiceData || (typeof jobDetails.currency === 'number')}
            disabled={!canSave || isCustomer || !editInvoiceData}
            data={jobDetails.currency}
            setData={data => updateJobDetails('currency', data)}
            required
            options={store.currencyOptions[0]}
          />
        </Col>
        <Col>
          <Select
            label="Incoterm"
            // disabled={!canSave || isCustomer || !editInvoiceData || (typeof jobDetails.incoterm === 'number')}
            disabled={!canSave || isCustomer || !editInvoiceData}
            data={jobDetails.incoterm}
            setData={data => updateIncoTerm(data)}
            options={store.incotermsOptions[0]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <ShowIf department={"OPS"} role={"SUPERUSER"}>

            {jobDetails.EPOStatus !== "Completed" &&
              <div className="registrationText my-2">Change Job status to:
                <button type="button" class="blue-button-teams" style={{ maxWidth: 'max-content', borderRadius: "10px", width: 'auto', whiteSpace: 'nowrap', paddingLeft: '15px', paddingRight: '15px', marginLeft: "10px" }} onClick={updateStatus}>
                  {jobDetails.PrevStatus}
                </button>
              </div>
            }

          </ShowIf>

        </Col>
        <Col>
          {isAdjustmentsInco &&

            (<div className={`input-outer-container`}>
              <label className="input-label" style={{ marginTop: '0px', marginBottom: '0px' }}>
                <button type="button" className="invoice-add-btn green-text" onClick={(e) => { setShowAdjustmentsModal(true) }}>
                  <span className="text-decoration-underline" style={{ fontSize: "0.9rem", fontStyle: 'italic' }}>
                    Adjustments
                  </span>
                  <span>
                    <OverlayTrigger
                      placement="top"
                      trigger={["hover", "focus"]}
                      overlay={
                        <Popover style={{ minWidth: "15rem" }}>
                          <PopoverContent>
                            {isAdjustmentsRequiredInco ? (
                              <>
                                <h6>Adjustments</h6>
                                <p>
                                  Freight Charges is mandatory for this Incoterm.
                                </p>
                              </>
                            ) : (
                              <>
                                <h6>Adjustments</h6>
                                <p>
                                  Adjustments are optional for this Incoterm.
                                </p>
                              </>
                            )}
                          </PopoverContent>
                        </Popover>
                      }
                    >
                      <div className="info" style={{ marginBottom: '-5px' }}></div>
                    </OverlayTrigger>
                  </span>
                </button>

              </label>
            </div>)
          }
        </Col>
      </Row>
      <EPOAdjustmentsModal editInvoiceData={editInvoiceData} show={showAdjustmentsModal} onHide={() => { setShowAdjustmentsModal(false) }} ref={formRef} />
    </div>
  )
}