
import React, { useCallback, useContext, useEffect, useMemo, useState, forwardRef, useImperativeHandle } from "react";
import { EpoJobDetailContext } from "../EpoJobDetails";
import DataTable from "../../../DataTable";
import { ExternalLinkAlt, Trash } from "@styled-icons/fa-solid";
import { Modal, ModalBody, ModalTitle, Tab, Table, Tabs } from "react-bootstrap";
import { useDataTableHandle } from "../../../DataTable/DataTable";
import { getEPO, getNewLoad, putEPO, deleteEPODocument, restoreEPODocument } from "../../../apiCalls";
import { Input } from "../../../Input/Input";
import GridTotals from "../../../GridTotals";
import { StoreContext } from '../../../Store';
import "./excel.css"
import MyModal from "./MyModal.js";
import Spreadsheet, { useSpreadsheetHandle } from "../../../Spreadsheet/Spreadsheet.js";
import DocsNavControls from "./DocsNavControls.js";
import BulkOperations from "./BulkOperations.js";
import { useParams, useNavigate } from 'react-router-dom';
import { useSecurity } from "../../../Security/security";
import restartSVG from "../../../../images/restart.svg";
import { DocViewer } from "../DocViewers";

export default function EpoJobInvoices() {
  const { jobDetails, setJobDetails, openedInvoice, setOpenedInvoice, tab, setTab, invoiceDetails, setInvoiceDetails, sumInvoice, setSumInvoice, setInvoiceId } = useContext(EpoJobDetailContext);

  const store = useContext(StoreContext);
  const navigate = useNavigate();
  const { validateRole } = useSecurity();

  useEffect(() => {
    async function fetchData() {
      getNewLoad("vat").then((data) => { store.VatOptions[1](data) });
    }
    fetchData();
  }, []);
  const fetchData = async () => {
    if (tab === 'invoices') {
      const getEPOTab = await getEPO(jobDetails.newLoadId, tab + 'tab')
      if (getEPOTab) {
        setInvoiceDetails(getEPOTab);
      }
    }
    else return
  }
  useEffect(() => {
    fetchData();
  }, [tab]);

  const handleDelete = async (invoice) => {
    const data = await deleteEPODocument(invoice?.invoiceId);
    if (data[0].completed === "Deleted") {
      fetchData();
    } else {
      const params = new URLSearchParams();
      params.append('authError', 'Sorry, something went wrong while performing that action.')
      navigate.push("/EpoJobDetails?" + params.toString());

    }
  };

  const handleRestore = async (event, invoice) => {
    event.preventDefault();
    const data = await restoreEPODocument(invoice?.invoiceId);
    if (data[0].completed === "Restored") {
      fetchData();
    } else {
      const params = new URLSearchParams();
      params.append('authError', 'Sorry, something went wrong while performing that action.')
      navigate("/EpoJobDetails?" + params.toString());
    }
  };

  const ConfirmActionModal = ({ show, onHide, onConfirm, confirmActionType, modalMessage }) => {
    return (
      <Modal centered show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>Confirm {confirmActionType}</Modal.Title>
          <button type="button" class="btn-close" aria-label="Close" onClick={onHide} />
        </Modal.Header>
        <Modal.Body style={{ fontSize: "110%" }}>
          <p>
            {modalMessage ?? 'Please confirm you action'}
            <br />
            <br />
          </p>
        </Modal.Body>
        <Modal.Footer
          class="d-flex flex-row p-3 gap-3 border-top bg-light justify-content-between align-items-center"
          style={{
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
          }}
        >
          <button class="cancel-button" onClick={() => onHide?.()}>
            Cancel
          </button>
          <button class="blue-button-teams red text-white" onClick={() => onConfirm?.()}>
            {confirmActionType ?? 'Confirm'}
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  const viewInvoice = (invoice) => {
    if (invoice.Items) {
      invoice.Items.map((row) => {
        if (row.VAT.length === 1 || row.VAT < 4) {
          row.VAT = store.VatOptions[0].find(opt => parseInt(opt.id) === parseInt(row.VAT))?.data;
        };
        return row;
      })
    }
    setInvoiceId(invoice?.invoiceId);
    setOpenedInvoice(invoice);
  };
  if (!invoiceDetails) return null;

  return (
    <>
      <EpoInvoiceModal invoice={openedInvoice} onClose={() => setOpenedInvoice(null)} sdiSubmitted={store?.sdiSubmitted} sfdSubmitted={store?.sfdSubmitted} eadSubmitted={store?.eadSubmitted} />

      <div>
        <DataTable data={invoiceDetails} className="table-striped table-bordered">
          <DataTable.Column colKey="filename" header="Filename" />
          <DataTable.Column colKey="doctype" header="Doc Type" />
          <DataTable.Column colKey="invStatus" header="Status" />
          <DataTable.Column colKey="itemRows" header="Total Rows"></DataTable.Column>
          <DataTable.Column colKey="lastModifiedUser" header="Last Modified By" />
          <DataTable.Column header="View" style={{ height: "1px", padding: 0 }}>
            {(invoice) => (
              <button className="external-table-link" onClick={() => viewInvoice(invoice)}>
                <ExternalLinkAlt width="1.1rem" height="1.1rem" color="#00857d" />
              </button>
            )}
          </DataTable.Column>
          {
            validateRole({ department: 'OPS', role: 'SUPERUSER' }) &&
            <DataTable.Column header="" style={{ textAlign: 'center', paddingTop: '6px', width: '80px', height: '45px' }}>
              {
                (invoice) => (
                  invoice.showIcon && (
                    invoice.deleted ? 
                    (
                      <button
                        className="reset-btn-filters restore-btn"
                        onClick={(event) => handleRestore(event, invoice)}
                        style={{ border: "0px" }}
                      >
                        <img
                          style={{ color: "blue" }}
                          src={restartSVG}
                          width="20"
                          alt="Spinning Arrow"
                        />
                      </button>
                    ) :
                    (
                      <Trash width="1.1rem" className="invoice-delete-icon" style={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDelete(invoice)} />
                    )
                  )
                )
              }
            </DataTable.Column>
          }
        </DataTable>
      </div>
    </>
  );
}

function EpoInvoiceModal({ invoice, onClose, sdiSubmitted, sfdSubmitted, eadSubmitted }) {
  const { jobDetails, setJobDetails, setOpenedInvoice, canSave, isCustomer, tab, setTab, invoiceDetails, setInvoiceDetails, sumInvoice, setSumInvoice, invoiceId, setInvoiceId, showBulkBtn, setServerData, fileBuffer, setBuffer, cycle, setCycle } = useContext(EpoJobDetailContext);
  const [localInvoiceData, setLocalInvoiceData] = useState();
  const [error, setError] = useState();
  const [showItems, setShowItems] = useState(true);
  const [selectedTab, setSelectedTab] = useState('summary');
  const [successText, setSuccessText] = useState(false);
  const spreadsheetHandle = useSpreadsheetHandle();
  const store = useContext(StoreContext);
  const childRef = React.createRef();
  const [showBulkModal, setShowBulkModal] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setSelectedTab('summary');
  }, [])

  const closeItems = () => {
    const fetchData = async () => {
      const getEPOTab = await getEPO(jobDetails.newLoadId, 'invoicestab');
      if (getEPOTab) {
        setInvoiceDetails(getEPOTab);
      }
      else return
    }
    fetchData();
    setSelectedTab('summary');
    setSuccessText(false);
    setCycle(false);
    onClose();
  }

  const mapEPOVat = async (epoItems) => {
    epoItems.map((row) => {
      if (row.VAT.length === 1 || row.VAT < 4) {
        row.VAT = store.VatOptions[0].find(opt => parseInt(opt.id) === parseInt(row.VAT))?.data;
      };
      return row;
    })
    return epoItems;
  }

  useEffect(() => {
    const fetchData = async () => {
      if (selectedTab === 'items') {
        // invoiceId added for the dynamic nav control
        const getEPOItems = await getEPO(invoiceId, selectedTab + 'tab')
        if (getEPOItems) {
          const updatedItems = await mapEPOVat(getEPOItems)
          if (updatedItems) {
            loadRows(getEPOItems)
          }
        }
      }
    };
    fetchData();
  }, [selectedTab])

  useEffect(() => {
    (invoice?.doctype !== "Commercial Document") ? setShowItems(false) : setShowItems(true)
    setLocalInvoiceData(invoice);
    if (invoice?.invoiceId) {
      getEPO(invoice.invoiceId, "Invoice").then((res) => {
        setBuffer(Buffer.from(res[0].invoice.data));
        setCycle(true)
      });
    }
  }, [invoice]);

  const loadRows = (items) => {
    const rows = items.map((i) => [
      { text: i.commcode, itemId: i.itemId },
      { text: i.origin },
      { text: i.commDesc },
      { text: i.packageCount },
      { text: i.packageType },
      { text: i.grossMass },
      { text: i.netMass },
      { text: i.loadValue },
      { text: i.quantity2 },
      { text: i.healthCert },
      { text: i.catchCert },
      { text: i.meursing },
      { text: i.organic },
      { text: i.VAT },
      { text: i.RGR },
      { text: i.preferential }
    ])
    spreadsheetHandle.current.excelDispatch({ type: "set", detail: rows });
    spreadsheetHandle.current.excelDispatch({ type: "log", detail: rows });
  }

  const itemDataHandler = useDataTableHandle();
  const handleTabSelect = (eventKey) => {
    if (eventKey === 'summary') {
      setError();
      setSuccessText(false);
    }
    setSelectedTab(eventKey);
  };

  const removeEmptyOrNull = (obj) => {
    Object.keys(obj).forEach(
      (k) =>
        (obj[k] && typeof obj[k] === "object" && removeEmptyOrNull(obj[k])) ||
        (!obj[k] && obj[k] !== undefined && delete obj[k]) ||
        (typeof obj[k] === "string" && obj[k].trim() === '' && delete obj[k])
    );
    return obj;
  };

  const validateHsRows = async () => {
    setError();
    if (!localInvoiceData.invoiceNumber) {
      setError("Please enter an Invoice number at the top of the page to continue.")
      return;
    }
    if (localInvoiceData.invoiceNumber.length >= 35) {
      setError(
        "Invoice number Cannot exceed 35 characters."
      );
      return;
    }
    // Collapses any completely empty rows and squeezes non-empty rows together.
    let collapsedRows = [];
    for (const idx in spreadsheetHandle.current.excelState.rows) {
      const row = spreadsheetHandle.current.excelState.rows[idx];
      if (row.some((cell) => cell.text !== "")) {
        collapsedRows.push(row);
      }
    }

    spreadsheetHandle.current.excelDispatch({ type: "set", detail: collapsedRows }) // update the visual rows to the newly collapsed - needed so error index = shown index.

    // Uses configuration 'keys' to construct array of item objects
    const items = collapsedRows.map((row, row_idx) => {
      const obj = {};
      spreadsheetHandle.current.excelState.config.forEach((col, idx) => {
        const { key } = col;
        obj[key] = row[idx]?.text ?? "";
        if (row[idx]?.itemId !== undefined) {
          obj["itemId"] = row[idx].itemId;
        }
      });
      return obj;
    });

    // Backend doesn't like empty strings or nulls, prefered straight 'undefined'.
    const filteredList = removeEmptyOrNull(items).filter(
      (value) => Object.keys(value).length !== 0
    );
    try {
      const data = await putEPO(
        {
          items: filteredList
            .map((item) => ({
              ...item,
              itemId: item.itemId <= 0 ? null : item.itemId,
            })),
          invId: invoiceId,
          id: jobDetails.newLoadId,
          invoiceNumber: localInvoiceData.invoiceNumber
        },
        "Items",
        jobDetails.newLoadId
      );
      //check if data[0].error is true OR if 'RGRvalid' is present in data[0]
      if (data[0]?.error === true || 'RGRvalid' in data[0]) {
        setSuccessText(true);
        const getEPOItems = await getEPO(invoiceId, 'items' + 'tab')
        if (getEPOItems) {
          const updatedItems = await mapEPOVat(getEPOItems)
          if (updatedItems) {
            loadRows(getEPOItems)
            setHsRowErrors(
              data,
            );
          }
        }
        const data1 = await getEPO(jobDetails.newLoadId, "Header");
        // const newId = jobDetails.newLoadId
        if (data1) {
          setJobDetails({ id, ...data1.Parents[0], });
          setServerData({ id, ...data1.Parents[0] });
        }
      }
      //if else
      else if (data[0]?.RGRValid === false || data[0]?.areaMatchValid === false || data[0]?.VATWarnValid === false) {
        setSuccessText(true);
        const getEPOItems = await getEPO(invoiceId, 'items' + 'tab')
        if (getEPOItems) {
          const updatedItems = await mapEPOVat(getEPOItems)
          if (updatedItems) {
            loadRows(getEPOItems)
            setHsRowErrors(
              data,
            );
          }
        }
        const data1 = await getEPO(jobDetails.newLoadId, "Header");
        // const newId = jobDetails.newLoadId
        if (data1) {
          setJobDetails({ id, ...data1.Parents[0], });
          setServerData({ id, ...data1.Parents[0] });
        }
      }

      else {
        const newDetails = data;
        const updatedItems = await mapEPOVat(newDetails)
        if (updatedItems) {
          loadRows(newDetails)
        }
        // loadRows(newDetails);
        // setOpenedInvoice(null);
        // setSelectedTab('summary');
        // setSumInvoice(0)
        setSuccessText(true);
        const data1 = await getEPO(jobDetails.newLoadId, "Header");
        // const newId = jobDetails.newLoadId
        if (data1) {
          setJobDetails({ id, ...data1.Parents[0], });
          setServerData({ id, ...data1.Parents[0] });
        }
        //Close popup
      }
    } catch (err) {
      console.error(err);
    }
  };

  const setHsRowErrors = useCallback((errors) => {
    const errorsPresent = errors[0].error === true; //check if the key error is present and equals true
    const keys = spreadsheetHandle.current.excelState.config.map((c) => c.key);
    const updateFunc = ((prevRows) => {
      for (let y = 0; y < prevRows.length; y++) {
        const row = keys.map((key, idx) => {
          // TODO: remove .toLowerCase() comparison and replace with simple errors[y][key] lookup once backend changed.
          if (idx === 0) {
            if (y < errors.length) {
              prevRows[y][idx].itemId = errors[y].itemId
            }
          }
          if (y < errors.length) {
            return {
              ...prevRows[y][idx],
              error: errorsPresent && Object.entries(errors[y]).find(
                ([k, v]) => k.toLowerCase() === `${key.toLowerCase()}error` // if errorsPresent then we set error to the cells that have errors
              )?.[1],
              warn: !errorsPresent && Object.entries(errors[y]).find(
                ([k, v]) => k.toLowerCase() === `${key.toLowerCase()}warn` // if errorsPresent is false, then we set the warnings to the cells that have warnings
              )?.[1],
            };
          }
        });
        prevRows[y] = [...row];
      }
      return [...prevRows];
    });

    spreadsheetHandle.current.excelDispatch({ type: "set", detail: updateFunc });
  }, [spreadsheetHandle.current.excelState.config]);

  const [pageNo, setPageNo] = useState(null);
  const totalDocs = invoiceDetails?.length;

  useEffect(() => {
    if (childRef.current) {
      const pageNumber = childRef.current.getPageNo() + 1;
      setPageNo(pageNumber);
    }

  }, [localInvoiceData?.invoiceId])

  const handleNext = () => {
    if (childRef.current) {
      childRef.current.loadNextDoc();
      setPageNo(prevNo => prevNo + 1);
      let doctype = invoiceDetails[pageNo].doctype;
      if (doctype === "Commercial Document") {
        setInvoiceId(invoiceDetails[pageNo].invoiceId);
      }
      (doctype !== "Commercial Document") ? setShowItems(false) : setShowItems(true)
    }
  };

  const handlePrev = () => {
    if (childRef.current) {
      childRef.current.loadPrevDoc();
      setPageNo(prevNo => prevNo - 1)
      let doctype = invoiceDetails[pageNo - 2].doctype;
      if (doctype === "Commercial Document") {
        setInvoiceId(invoiceDetails[pageNo - 2].invoiceId);
      }
      (doctype !== "Commercial Document") ? setShowItems(false) : setShowItems(true)
    }
  };

  const dowloadFile = () => {
    if (childRef.current) {
      childRef.current.dowloadFile();
    }
  }

  if (!localInvoiceData?.invoiceId) return null;

  return (
    <MyModal show={localInvoiceData?.invoiceId} centered contentClassName="invoice-modal" dialogClassName="superwide-modal" onHide={() => null} onScroll={() => spreadsheetHandle.current.excelDispatch({ type: "recalculate" })}>
      <MyModal.Header>
        <MyModal.Title>Viewing: {invoiceDetails[pageNo - 1]?.filename}</MyModal.Title>
        {selectedTab === "summary" && <DocsNavControls
          dowloadFile={dowloadFile}
          handleNext={handleNext}
          pageNo={pageNo}
          handlePrev={handlePrev}
          onClose={closeItems}
          totalDocs={totalDocs} />}
      </MyModal.Header>
      <MyModal.Body className="flex-grow-1">
        <Tabs onSelect={handleTabSelect}>
          <Tab title="Summary" eventKey="summary">
            <EpoInvoiceSummary invoiceId={localInvoiceData?.invoiceId} ref={childRef} />
          </Tab>
          {showItems &&
            <Tab title="Items" eventKey="items">
              <EpoInvoiceItems ref={spreadsheetHandle} spreadsheetVisible={selectedTab === "items"} localInvoiceData={localInvoiceData} itemDataHandler={itemDataHandler} setLocalInvoiceData={setLocalInvoiceData} sdiSubmitted={sdiSubmitted} sfdSubmitted={sfdSubmitted} eadSubmitted={eadSubmitted} showBulkModal={showBulkModal} onBulkClose={() => setShowBulkModal(false)} pageNo={pageNo - 1} />

            </Tab>
          }
        </Tabs>
      </MyModal.Body>
      <MyModal.Footer>
        <div className='d-flex flex-row w-100 py-3 px-3 align-self-center align-items-center justify-content-start'>
          {selectedTab === 'items' && showBulkBtn && <button onClick={() => setShowBulkModal(true)} class="blue-button-teams green-btn" style={{ borderRadius: "10px" }}>
            Bulk Action
          </button>}
        </div>
        <div className='d-flex flex-row pt-2 align-items-center justify-content-end' style={{ color: "#842029", width: '60%' }}>{selectedTab === 'items' && error}</div>
        {
          successText && <div className="d-flex flex-row py-4 px-3 gap-3 justify-content-end">
            <span className="text-success bold">Successfully&nbsp;saved.</span>
          </div>
        }
        <div className="d-flex flex-row py-3 px-3 gap-3 justify-content-end">
          <button type="button" class="cancel-button" onClick={closeItems} style={{ display: "flex", borderRadius: "10px", backgroundColor: "#ffbe00" }}>
            Back
          </button>
          {canSave && (!isCustomer) && (
            <button type="button" disabled={(selectedTab === 'summary') ? true : false} class="blue-button-teams red-btn" style={{ borderRadius: "10px" }} onClick={validateHsRows}>
              Save
            </button>
          )}
        </div>
      </MyModal.Footer>
    </MyModal>
  );
}

const EpoInvoiceSummary = forwardRef((props, ref) => {
  const { invoiceId } = props;
  const { invoiceDetails, setInvoiceId, fileBuffer, setBuffer, cycle, setCycle } = useContext(EpoJobDetailContext);
  const [curDocIndex, setCurDocIndex] = useState(invoiceId || null);

  const invoiceIndex = invoiceDetails?.findIndex(item => item.invoiceId === invoiceId);

  const [curIndex, setCurIndex] = useState(() => invoiceIndex);
  const [pageNo, setPageNo] = useState(curIndex);
  const totalDocs = invoiceDetails?.length;

  useEffect(() => {
    if (curDocIndex && cycle) {
      getEPO(curDocIndex, "Invoice").then((res) => {
        setBuffer(Buffer.from(res[0].invoice.data));
      });
    }
  }, [curDocIndex]);

  const loadNextDoc = () => {
    if (curIndex < totalDocs) {
      setCurIndex(preIndex => preIndex + 1);
      setCurDocIndex(invoiceDetails[curIndex + 1]["invoiceId"]);
      setPageNo(preNo => preNo + 1)
    }
  };

  const loadPrevDoc = () => {
    if (curIndex > 0) {
      setCurIndex((prevIndex) => prevIndex - 1);
      setCurDocIndex(invoiceDetails[curIndex - 1]["invoiceId"]);
      setPageNo((prevPageNo) => prevPageNo - 1);
    }
  };

  const getPageNo = () => {
    return pageNo;
  };

  // Expose the function to the parent component using useImperativeHandle
  useImperativeHandle(ref, () => ({
    loadNextDoc,
    loadPrevDoc,
    dowloadFile,
    getPageNo
  }));

  const dowloadFile = () => {
    if (!fileBuffer) return;
    var fileURL = window.URL.createObjectURL(new Blob([fileBuffer]));
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = fileURL;
    a.download = invoiceDetails[curIndex].filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(fileURL);
      document.body.removeChild(a);
    }, 0);
  };

  const filetype = invoiceDetails[curIndex].filename.split('.').slice(-1)[0].toLowerCase();

  return (
    <section className="p-2 d-flex flex-column justify-content-around gap-2 mt-3">
      <div
        className="p-3 rounded-3"
        style={{ height: "540px", overflowY: "auto", border: "2px solid hsl(0, 0%, 75%)", cursor: "pointer" }}
      >
        <div className="invoice-preview">
          <DocViewer fileBuffer={fileBuffer} filetype={filetype} />
        </div>
      </div>
    </section>
  );
})

const EpoInvoiceItems = forwardRef(({ onBulkClose = { onBulkClose }, localInvoiceData, setLocalInvoiceData, spreadsheetVisible, itemDataHandler, sdiSubmitted, sfdSubmitted, eadSubmitted, showBulkModal, pageNo }, ref) => {
  const { canSave, isCustomer, jobDetails, setShowBulkBtn, setInvoiceDetails, invoiceDetails } = useContext(EpoJobDetailContext);

  const [packageTypes, setPackageTypes] = useState([]);
  const [origins, setOrigins] = useState([]);
  const [vatOptions, setVatOptions] = useState([]);

  const [netMass, setNetMass] = useState(0);
  const [grossMass, setGrossMass] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [itemRows, setItemRows] = useState(0);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const getEPOTab = await getEPO(jobDetails?.newLoadId, 'invoices' + 'tab')
      if (getEPOTab && getEPOTab?.length > 0) {
        setInvoiceDetails(getEPOTab);
      }
    }
    fetchData()
  }, [pageNo])

  useEffect(() => {
    getNewLoad("vat").then((data) => { setVatOptions(data) });
    getNewLoad("loadtype").then((types) => setPackageTypes(types.map((t) => ({ id: t.data, data: t.data }))));
    getNewLoad("countrycode").then((origins) =>
      setOrigins(
        origins
          .map((t) => ({ id: t.data, data: t.data }))
          .sort((a, b) => {
            if (a.data > b.data) return 1;
            if (a.data < b.data) return -1;
            return 0;
          })
      )
    );

    setLocalInvoiceData((prev) => {
      if (prev.Items?.length > 0) return prev;

      const temp_items = [];
      for (let i = 1; i <= 2; i++) {
        temp_items.push({
          itemId: 0 - i,
          commcode: "",
          origin: "",
          commDesc: "",
          packageCount: undefined,
          packageType: "",
          grossMass: undefined,
          netMass: undefined,
          organic: undefined,
          healthCert: undefined,
          catchCert: undefined,
          meursing: undefined,
          loadValue: undefined,
          quantity2: undefined,
          VAT: undefined,
          RGR: undefined,
          preferential: undefined
        });
      }
      return {
        ...prev,
        Items: temp_items,
      };
    });
  }, [setLocalInvoiceData]);

  useEffect(() => {
    const sumsFunction = (state) => {
      let headers = [
        { name: "grossMass", update: setGrossMass },
        { name: "netMass", update: setNetMass },
        { name: "loadValue", update: setTotalValue },
        { name: "packageCount", update: setItemCount }
      ];
      let itemRowsCalc = 0;
      const isArray = Array.isArray(state.rows?.[0])
      headers = headers.map((obj) => { return { ...obj, value: 0, col: isArray ? state.config.findIndex((c) => c.key === obj.name) : undefined } });

      state.rows.forEach((row) => {
        headers.forEach((h) => {
          const key = isArray ? h.col : h.name
          h.value += isNaN(parseFloat(row[key]?.text)) ? 0 : parseFloat(row[key]?.text);
        })
        itemRowsCalc += (isArray ? row : Object.values(row)).some((cell) => !!cell.text) ? 1 : 0;
      })

      headers.forEach((h) => {
        h.update(h.value);
      })

      setItemRows(itemRowsCalc);
    }

    ref.current.excelDispatch({ type: "setOnUpdate", detail: sumsFunction });
  }, [])

  useEffect(() => {
    (itemRows >= 1) ? setShowBulkBtn(true) : setShowBulkBtn(false)
  }, [itemRows])
  const isEditable = useMemo(() => {
    return canSave && (sfdSubmitted[0] === 0 && sdiSubmitted[0] === 0 && eadSubmitted[0] === 0);
  }, [canSave, sfdSubmitted, sdiSubmitted, eadSubmitted]);

  useEffect(() => {
    if (invoiceDetails?.length > 0 && pageNo >= 0) {
      let invoiceNo = invoiceDetails[pageNo].invoiceNumber;
      if (invoiceNo === null) {
        invoiceNo = ""
      }
      setLocalInvoiceData(prev => {
        return {
          ...prev,
          invoiceNumber: invoiceNo
        }
      })
    }
  }, [pageNo, invoiceDetails])

  const validateStringLength = (value, length) => {
    setLocalInvoiceData(prev => { ; return { ...prev, invoiceNumber:value } })
    if(value.length >= length){
        setError("Invoice number Cannot exceed 35 characters.");
    } else {
      setError("");
    }
  }

  return (
    <>
      {
        showBulkModal && <BulkOperations key="epoLoads" id="epoLoads" onBulkClose={onBulkClose} itemCount={itemRows} invoiceNumber={localInvoiceData?.invoiceNumber} excelState={ref?.current.excelState} excelRef={ref?.current} totalValue={totalValue} />
      }
      <GridTotals
        other={[
          <Input
            label="Invoice No." name="invoiceNumber"
            type="text" required value={localInvoiceData.invoiceNumber}
            onChange={e => validateStringLength(e.target.value, 35)}
            disabled={(!canSave) || ((sdiSubmitted[0] === 1) || (sfdSubmitted[0] === 1) || (eadSubmitted[0] === 1))}
            className="formattedText"
            error={error}
          />
        ]}
        grossKG={grossMass}
        netKG={netMass}
        value={totalValue}
        itemCount={itemCount}
        rows={itemRows}
        additionalStyles={{ marginTop: "2rem", marginBottom: "1rem" }}
      />

      <section className="p-2 d-flex flex-column">
        <Spreadsheet defaultRows={15} editable={isEditable} visible={spreadsheetVisible} handle={ref}>
          <Spreadsheet.Column key="commcode" type="text" minWidth={13} align="center">
            HS Code
          </Spreadsheet.Column>
          <Spreadsheet.Column key="origin" type="text" minWidth={8} align="center">
            Origin
          </Spreadsheet.Column>
          <Spreadsheet.Column key="commDesc" stretch type="text" minWidth={16}>
            Description
          </Spreadsheet.Column>
          <Spreadsheet.Column key="packageCount" type="number" minWidth={12}>
            No. Items
          </Spreadsheet.Column>
          <Spreadsheet.Column key="packageType" type="text" minWidth={13} >
            Type
          </Spreadsheet.Column>
          <Spreadsheet.Column key="grossMass" type="number" minWidth={12}>
            Gross Mass
          </Spreadsheet.Column>
          <Spreadsheet.Column key="netMass" type="number" minWidth={12}>
            Net Mass
          </Spreadsheet.Column>
          <Spreadsheet.Column key="loadValue" type="number" minWidth={13}>
            Value
          </Spreadsheet.Column>
          <Spreadsheet.Column key="quantity2" type="number" minWidth={10}>
            Qty 2
          </Spreadsheet.Column>
          <Spreadsheet.Column key="healthCert" type="text" minWidth={25}>
            Health Cert
          </Spreadsheet.Column>
          <Spreadsheet.Column key="catchCert" type="text" minWidth={14}>
            Catch Cert
          </Spreadsheet.Column>
          <Spreadsheet.Column key="meursing" type="text" minWidth={10}>
            Meursing
          </Spreadsheet.Column>
          <Spreadsheet.Column key="organic" type="text" minWidth={9}>
            Organic
          </Spreadsheet.Column>
          <Spreadsheet.Column key="VAT" type="text" minWidth={8}>
            VAT
          </Spreadsheet.Column>
          <Spreadsheet.Column key="rgr" type="text" minWidth={9}>
            RGR
          </Spreadsheet.Column>
          <Spreadsheet.Column key="preferential" type="text" minWidth={8}>
            Pref
          </Spreadsheet.Column>
        </Spreadsheet>
      </section>
    </>
  );
})