import { useContext, useEffect, useState } from "react";
import DataTable from "../DataTable";
import { ExternalLinkAlt } from "@styled-icons/fa-solid";
import { getEPO } from "../apiCalls";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../Store";
import { DocViewerModal } from "../EpoListLoads/Details/DocViewers";
window.Buffer = window.Buffer || require("buffer").Buffer;

export default function EpoJobInvoices() {
  const store = useContext(StoreContext);
  const [jobDetails, setJobDetails] = useState(null);
  const navigate = useNavigate();

  const [openedInvoice, setOpenedInvoice] = useState(null);

  const viewInvoice = (invoice) => {
    setOpenedInvoice(invoice);
  };

  function goBack() {
    navigate("/EpoListLoads")
  }

  useEffect(() => {
    async function fetchDetails() {
      const data = await getEPO(store.newLoadID[0], "Download");
      setJobDetails(data);
    }
    fetchDetails();
  }, [store.newLoadID[0]]);

  if (!jobDetails) return null;

  return (
    <>
      <div className="full-width-container">
        <section className="floating-box" style={{ maxWidth: "1040px" }}>
          <div className="floating-box-header">
            <span>Downloads</span>
          </div>
          <DocViewerModal invoice={openedInvoice} onClose={() => setOpenedInvoice(null)} />
          <div>
            <DataTable data={jobDetails} className="table-striped table-bordered">
              <DataTable.Column colKey="filename" header="Filename" />
              <DataTable.Column colKey="invStatus" header="Status" />
              <DataTable.Column header="View" style={{ height: "1px", padding: 0 }} headerStyle={{ textAlign: "center" }}>
                {(invoice) => (
                  <button className="external-table-link" onClick={() => viewInvoice(invoice)}>
                    <ExternalLinkAlt width="1.1rem" height="1.1rem" color="#00857d" />
                  </button>
                )}
              </DataTable.Column>
            </DataTable>
          </div>
          <button className="blue-button-teams green-btn" style={{ margin: "10px" }} onClick={goBack} >Back</button>
        </section>
      </div>
    </>
  );
}