import React from "react";
import { Container } from "react-bootstrap";
import DataTable from "../../Reports/DataTable1";
import GridTotals from "../../GridTotals";

const inputStyle = {
    width: "100%",
    boxSizing: "border-box",
    border: "none",
    backgroundColor: "transparent",
};

// Default Header Styles
const headerStyle = { fontSize: "12px", textTransform: "capitalize" };
// Default Row Styles
const defaultStyle = { fontSize: '14px', width: 'auto'  };
const buttonStyle = {backgroundColor: 'transparent', border: 'none', height:'4rem', width: '4rem'}
const imageStyle = { height:'1.2rem', width: '1.2rem', marginRight:'8px', marginTop: '2px' };
// Override individual row styles

const fieldStatus_Warning = 'warningHighlight';
const fieldStatus_Error = 'invalidHighlight';

const ItemChecklist = ({ totalPackageCountLabel, totalPackageCount, totalGrossMassLabel, totalGrossMass, totalNetMassLabel, totalNetMass, totalValueLabel, totalValue, totalStatValueLabel, totalStatValue, totalDutyLabel, totalDuty, totalVATLabel, totalVAT, productList, productListForQATabTable, prevPage, submitLoadAsDraft, submitLoad }) => {

    return (
        <>
            <Container fluid>
                <div className="bp-section-heading">Item Checklist</div>
                <GridTotals 
                    totalPackageCountLabel={totalPackageCountLabel}
                    itemRowsCount={productList.length}
                    totalGrossMassLabel={totalGrossMassLabel}
                    grossKG={totalGrossMass}
                    totalNetMassLabel={totalNetMassLabel}
                    netKG={totalNetMass}
                    totalValueLabel={totalValueLabel}
                    value={totalValue}
                    totalStatValueLabel={totalStatValueLabel}
                    totalStatValue={totalStatValue}
                    totalDutyLabel={totalDutyLabel}
                    totalDuty={totalDuty}
                    totalVATLabel={totalVATLabel}
                    totalVAT={totalVAT}
                    packagesCount={totalPackageCount}
                    additionalStyles={{ marginBottom: "1rem" }}
                />
                <DataTable
                    // columns={columns}
                    // containerRef={containerRef}
                    initialData={productListForQATabTable}
                    disabled={true}
                    defaultStyle={defaultStyle}
                    headerStyle={headerStyle}
                    sortable={false}
                    filterable={false}
                    inputStyle={inputStyle}
                    rootClose={false}
                    downloadable={false}
                    // buttonImage={copyIcon}
                    buttonDisabled={false}
                    buttonStyle={buttonStyle}
                    imageStyle={imageStyle}
                    //buttonOnClick={handleViewDocument}
                    hideNavigation={true}
                    //handleDeleteRow={localDeleteRow}
                    //handleRestoreRow={localRestoreRow}
                    //superOps={validateRole({department: 'OPS', role: 'SUPERUSER'}) ? true : false}
                    // returnPrev={() => history.push('/ReportsAdmin')}
                />
            </Container>
            <div className="d-flex flex-row p-3 mt-4 justify-content-between full-width-container position-absolute bottom-0">
                <button
                    className="blue-button-teams green-btn"
                    type="button"
                    style={{ fontSize: "1.25rem", width: "11rem" }}
                    onClick={(e) => submitLoadAsDraft(e)}
                >
                    Save as Draft
                </button>
                <button
                    className="blue-button-teams"
                    type="button"
                    style={{ fontSize: "1.25rem", width: "11rem" }}
                    onClick={(e) => submitLoad(e)}
                >
                    Submit Load
                </button>
            </div>
        </>
    );
};

export default ItemChecklist;