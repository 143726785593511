import React from "react";
import InvoiceViewer from "./InvoiceViewer.js";
import PdfViewer from "./PdfViewer.js";

const DocViewer = ({ fileBuffer, filetype }) => {
    if (!fileBuffer) return null;

    if (fileBuffer) {
        if (filetype === "xlsx" || filetype === "xls") {
            return (<InvoiceViewer fileBuffer={fileBuffer} />);
        }
        if (filetype === "pdf") {
            return (<PdfViewer fileBuffer={fileBuffer} />);
        }
        if (filetype === "png" || filetype === "jpeg" || filetype === "jpg") {
            return (
                <img alt="" src={`data:image/${filetype};base64,${fileBuffer.toString("base64")}`} style={{ maxWidth: "100%", minWidth: "50%" }} />
            );
        }
    }
    else {
        return <div>Unable to preview file.</div>;
    }
};

export default DocViewer;