import React, { useState, useEffect, useCallback, useContext, useMemo } from "react";
import "./table.css";
import "./report.css";
import { getUserDetails, putUserDetails, deletUserDetails, restoreUserDetails, getUsers, postUserDetails, postAdminPasswordreset } from "./apiCalls";
import { Input, Select, Switch } from "./Input/Input.js";
import { useNavigate } from "react-router-dom";
import { Col, Row, Modal, OverlayTrigger, Popover, PopoverContent } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { Eye } from "@styled-icons/fa-solid";
import { useSecurity, ShowIf } from "./Security/security";
import { StoreContext } from "./Store";

const emptyUser = {
  id: null,
  userName: "",
  userPassword: "",
  userPasswordConfirm: "",
  firstname: "",
  lastname: "",
  userEmail: "",
  department: "",
  role: "STANDARD", // TODO: maybe remove this default?
  admin: false,
  lockedOut: false,
  disabled: false,
};

const UserDetails = (props) => {
  const [userData, setUserData] = useState(emptyUser);
  const store = useContext(StoreContext);
  const updateUserData = useCallback((key, value) => {
    if(key ==='role' && !getRoleCanBeAdmin(value)) setUserData((prev) => ({ ...prev, admin: false }));//will check if the current role can be admin and if not will set admin to false
    setUserData((prev) => ({ ...prev, [key]: value }));
  }, []);

  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [passwordSent, setPasswordSent] = useState(false);

  const { id } = useParams();

  const { securityState, validateRole } = useSecurity();
  const { userid, department } = securityState;

  const isNew = useMemo(() => id === undefined, [id]);
  const isSelf = useMemo(() => id?.toString() === userid?.toString(), [id, userid]);
  const isEori = useMemo(() => validateRole({isCustomer: false, isLoggedIn: true}), []);
  const isTrader = useMemo(() => department?.toLowerCase() === 'trader', []);


  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      if (id) {
        const [data] = await getUserDetails(id);
        setUserData(data);
      } else {
        setUserData(emptyUser);
      }
      const deparment = sessionStorage.getItem('department').toUpperCase()

      //if account isEeori, we make a request to get the departments, and we populate the options for the dropdown. If user is not Eori, we set the department to the department of the user
      const deps = isEori ? (await getUsers("departmentList")).map(dep => ({id: dep.department.toUpperCase(), data: dep.department.toUpperCase()})) : [{id: deparment, data: deparment}];

      //if account isEeori, we make a request to get the roles, and we populate the options for the dropdown. If user is not Eori, we set the role options to 'standard' only
      let roles = '';
      if(isEori){
        roles = (await getUsers("rolesList")).map(role => ({id: role.role.toUpperCase(), data: role.role.toUpperCase(), canBeAdmin: role.canBeAdmin}));
      }
      else if (isTrader){
        //make the same call as isEori but filter the roles to only show 'standard' and 'super' roles
        roles = (await getUsers("rolesList")).filter(role => role.role.toUpperCase() === 'STANDARD' || role.role.toUpperCase() === 'SUPER').map(role => ({id: role.role.toUpperCase(), data: role.role.toUpperCase(), canBeAdmin: role.canBeAdmin}));
      }
      else{
        roles = [{id: 'STANDARD', data: 'STANDARD', canBeAdmin: 0}];
      }

      setDepartmentOptions(deps);
      setRoleOptions(roles);
    }
    fetchData();
  }, [id, isEori]);

  const handleDelete = async () => {
    if (isSelf) return;
    const data = await deletUserDetails(userData.id);
    if (data[0].completed === "Deleted") {
      navigate("/UserAdmin");
    } else {
      const params = new URLSearchParams();
      params.append('authError', 'Sorry, something went wrong while performing that action.')
      navigate.push("/UserAdmin?" + params.toString());
    }
  };

  const handleRestore = async () => {
    if (isSelf) return;
    const data = await restoreUserDetails(userData.id);
    if (data[0].completed === "Restored") {
      navigate("/UserAdmin");
    } else {
      const params = new URLSearchParams();
      params.append('authError', 'Sorry, something went wrong while performing that action.')
      navigate("/UserAdmin?" + params.toString());
    }
  };

  const handlePwdReset = async (event) => {
    event.preventDefault();
    try {
      const fd = new FormData();
      fd.append("username", userData?.userName);
      const data = await postAdminPasswordreset(fd);      
      if (data.email === "Invalid") {
        setPasswordSent(false);
      }
      if (data.email === "Password reset email sent.") {
        setPasswordSent(true);
      }
      // if (data.email === "Password was changed only recently.") {
      //   alert(data.email);
      // }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isSelf) return;
    try {
      const freshErrors = {};
      if (!userData.userName) freshErrors.userName = "Required";
      if (isNew && !userData.userPassword) freshErrors.userPassword = "Required";
      else if (isNew && userData.userPassword.match("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$") == null)
        freshErrors.userPassword = "Password must be minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character from (@ $ ! % * ? &)"
      if (userData.userPassword && (userData.userPassword !== userData.userPasswordConfirm)) freshErrors.userPasswordConfirm = "Passwords must match"
     
        
      if (!userData.firstname) freshErrors.firstname = "Required";
      if (!userData.lastname) freshErrors.lastname = "Required";

      if (!userData.department) freshErrors.department = "Required";

      setErrors(freshErrors);
      if (Object.values(freshErrors).some(val => !!val)) {
        console.log("Some form of error...");
        return;
      }
      const fd = new FormData();
      fd.append('userName', userData.userName);
      fd.append('firstname', userData.firstname);
      fd.append('lastname', userData.lastname);
      fd.append('userEmail', userData.userName);
      fd.append('department', userData.department);
      fd.append('role', userData.role);
      fd.append('companyId',store?.userIdSelected[0])
      if (userData.disabled) fd.append('disabled', userData.disabled);
      if (userData.lockedOut) fd.append('lockedOut', userData.lockedOut);
      fd.append('admin', userData.admin);
      if (isNew) {
        fd.append('userPassword', userData.userPassword);
        const data = await postUserDetails(fd);
        const [{ completed }] = data;
        if (completed === "Inserted") {
          navigate('/UserAdmin');
        } else {
          const params = new URLSearchParams();
          params.append('authError', 'Sorry, something went wrong while performing that action.')
          navigate('/UserAdmin?' + params.toString());
        }
      } else {
        fd.append('id', id);
        fd.append('userPassword', '');
        const data = await putUserDetails(fd);
        const [{ completed }] = data;
        if (completed === "Updated") {
          navigate('/UserAdmin');
        } else {
          const params = new URLSearchParams();
          params.append('authError', 'Sorry, something went wrong while performing that action.')
          navigate('/UserAdmin?' + params.toString());
        }
      }
      return;
    } catch (err) {
      console.log(err);
    }
  };

  const getRoleCanBeAdmin = (role) => {
    const roleOption = roleOptions.find(option => option.id.toLowerCase() === role.toLowerCase());
    return roleOption ? roleOption.canBeAdmin : 0;
  };

  return (
    <div className="full-width-container">
      <ConfirmDeleteModal
        userData={userData}
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
      />
      <ConfirmRestoreModal
        userData={userData}
        show={showRestoreModal}
        onHide={() => setShowRestoreModal(false)}
        onConfirm={handleRestore}
      />
      <form className="floating-box" style={{ maxWidth: "1040px" }} onSubmit={handleSubmit}>
        <div className="floating-box-header">
          <span>{isNew ? "Create" : "Edit"} User</span>
        </div>
        <div className="p-3 d-flex flex-column gap-2">
          <Row>
            <Col>
              <Input
                label="Username*"
                error={errors.userName}
                value={userData?.userName}
                onChange={(e) => updateUserData("userName", e.target.value)}
                disabled={isSelf}
              />
            </Col>
            <Col />
            </Row>
            <Row>
            <Col>
              <Input
                className='input-smaller-text'
                type={showPassword ? 'text' : 'password'}
                label="Password*"
                placeholder={isNew ? "Type your password" : ''}
                error={errors.userPassword}
                value={isNew ? userData.userPassword : "*************"}
                onChange={(e) => updateUserData("userPassword", e.target.value)}
                disabled={!isNew || isSelf}
                suffix={isNew && (
                  <button type="button" className={`eye-hider ${showPassword ? 'show' : 'hide'}`} onClick={() => setShowPassword(prev => !prev)}>
                    <Eye className="" width="1.3em" height="1.3em" />
                  </button>
                )}
              />
              </Col>
              <Col>
              <Input
                className='input-smaller-text'
                type={showPassword ? 'text' : 'password'}
                label="Confirm Password*"
                placeholder={isNew ? "Confirm your password" : ''}
                error={errors.userPasswordConfirm}
                value={isNew ? userData.userPasswordConfirm : "*************"}
                onChange={(e) => updateUserData("userPasswordConfirm", e.target.value)}
                disabled={!isNew || isSelf}
                suffix={isNew && (
                  <button type="button" className={`eye-hider ${showPassword ? 'show' : 'hide'}`} onClick={() => setShowPassword(prev => !prev)}>
                    <Eye className="" width="1.3em" height="1.3em" />
                  </button>
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input disabled={isSelf} label="First Name*" error={errors.firstname} value={userData?.firstname} onChange={(e) => updateUserData("firstname", e.target.value)} />
            </Col>
            <Col>
              <Input disabled={isSelf} label="Last Name*" error={errors.lastname} value={userData?.lastname} onChange={(e) => updateUserData("lastname", e.target.value)} />
            </Col>
          </Row>

          <Row>
            <Col>
              <Select
                disabled={isSelf}
                label="Department*"
                options={departmentOptions}
                error={errors.department}
                data={userData?.department?.toUpperCase?.()}
                setData={(data) => updateUserData("department", data)}
                info={
                  isEori && (
                  <OverlayTrigger
                    placement="right"
                    trigger={["hover", "focus"]}
                    overlay={
                      <Popover style={{ minWidth: "25rem" }}>
                        <PopoverContent>
                          <h6>Departments</h6>
                          <ul>
                            <li>Customer Management Team (CMT) - deals with new clients and manages customer data.</li>
                            <li>Operations Team (OPS) - main customs team, responsible for reviewing and submitting declarations.</li>
                            <li>Data Control Team (DCT) - deals with incoming data and transforms into usable state for declarations.</li>
                            <li>Finance (FIN) - manages financial data and credit checks.</li>
                          </ul>
                        </PopoverContent>
                      </Popover>
                    }
                  >
                    <div className="info"></div>
                  </OverlayTrigger>)
                }
              />
            </Col>
            <Col>
              <Select
                disabled={isSelf}
                label="Access Level*"
                options={userData.department === 'TRADER' ? roleOptions.filter(e => e.data == 'STANDARD' || e.data == 'SUPER') : roleOptions} //userData?.department?.toUpperCase?.()
                error={errors.role}
                data={userData?.role?.toUpperCase?.()}
                setData={(data) => updateUserData("role", data)}
                disableSelectOption={true}
              />
            </Col>
          </Row>
          <ShowIf isCustomer={false} >
          <hr />
          </ShowIf>
          <div className="d-flex flex-column gap-2">           
            {!isNew && (
              <>
               {validateRole({department: 'IT'}) && <button className="status-button light" style={{width:"250px", height:"50px"}} onClick={handlePwdReset}>Password Reset<div className={passwordSent ? "tick-success" : ""}></div></button>}
                <Switch
                  disabled={isSelf}
                  checked={userData.lockedOut}
                  onChange={(e) => updateUserData("lockedOut", e.target.value)}
                  className="d-flex gap-3 text-eoriblue align-self-end mt-2"
                >
                  <span>Account Locked Out</span>
                </Switch>
                <Switch
                  disabled={isSelf}
                  checked={userData.disabled}
                  onChange={(e) => updateUserData("disabled", e.target.value)}
                  className="d-flex gap-3 text-eoriblue align-self-end mt-2"
                >
                  <span>Account Disabled</span>
                </Switch>
              </>
            )}

              <Switch
                disabled={isSelf || getRoleCanBeAdmin(userData.role) === 0}
                checked={userData.admin}
                onChange={(e) => updateUserData("admin", e.target.value)}
                className="d-flex gap-3 text-eoriblue align-self-end align-items-center mt-2"
              >
                <span className="d-inline-flex align-items-center gap-1">
                  <OverlayTrigger
                    placement="left"
                    trigger={["hover", "focus"]}
                    overlay={
                      <Popover style={{ minWidth: "25rem" }}>
                        <PopoverContent>
                          <p>
                            Users with 'Admin' status have the ability to amend other users within their organisation such as changing department or locking their account.
                          </p>
                        </PopoverContent>
                      </Popover>
                    }
                  >
                    <div className="info"></div>
                  </OverlayTrigger>
                  Account Admin
                </span>
              </Switch>

          </div>
        </div>
        <div
          class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light align-items-center"
          style={{ borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}
        >
          <button className="blue-button-teams green-btn text-decoration-none" onClick={() => navigate('/UserAdmin')}>
            Back
          </button>
          <div className="flex-grow-1" />

          {!isNew && (userData.deleted ? (
            <button  disabled={isSelf} style={{opacity: isSelf ? '70%' : '100%', cursor: isSelf ? 'not-allowed' : undefined}} type="button" class="blue-button-teams red text-white" onClick={() => setShowRestoreModal(true)}>
              Restore
            </button>
          ) :
          (
            <button  disabled={isSelf} style={{opacity: isSelf ? '70%' : '100%', cursor: isSelf ? 'not-allowed' : undefined}} type="button" class="blue-button-teams red text-white" onClick={() => setShowDeleteModal(true)}>
            Delete
          </button>
          )
          )}
          <button disabled={isSelf} style={{opacity: isSelf ? '70%' : '100%', cursor: isSelf ? 'not-allowed' : undefined}} type="submit" class="blue-button-teams green-btn">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

const ConfirmDeleteModal = ({ show, userData, onHide, onConfirm }) => {
  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Confirm Delete</Modal.Title>
        <button type="button" class="btn-close" aria-label="Close" onClick={onHide} />
      </Modal.Header>
      <Modal.Body style={{ fontSize: "110%" }}>
        <p>
          Are you sure you want to delete user '{userData.userName}'?
          <br />
          <br />
        </p>
      </Modal.Body>
      <Modal.Footer
        class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light justify-content-between align-items-center"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >
        
        <button class="cancel-button" onClick={() => onHide?.()}>
          Cancel
        </button>

        <button class="blue-button-teams red text-white" onClick={() => onConfirm?.()}>
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const ConfirmRestoreModal = ({ show, userData, onHide, onConfirm }) => {
  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Confirm Restore</Modal.Title>
        <button type="button" class="btn-close" aria-label="Close" onClick={onHide} />
      </Modal.Header>
      <Modal.Body style={{ fontSize: "110%" }}>
        <p>
          Are you sure you want to restore user '{userData.userName}'?
          <br />
          <br />
        </p>
      </Modal.Body>
      <Modal.Footer
        class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light justify-content-between align-items-center"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >
        
        <button class="cancel-button" onClick={() => onHide?.()}>
          Cancel
        </button>

        <button class="blue-button-teams red text-white" onClick={() => onConfirm?.()}>
          Restore
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserDetails;
