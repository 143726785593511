import { Modal, Button, Tabs, Tab } from "react-bootstrap";
import hscode_img from "../../../images/hscode-example.png";

const InfoModal = ({ showModal, setShowModal, tabKey, setKey }) => {
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header className="floating-box-header-newload">
        Additional Information
      </Modal.Header>
      <Modal.Body>
        <Tabs
          id="controlled-tab-example"
          className="mb-2"
          activeKey={tabKey}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="commoditycode" title="HS Code" tabClassName="tab-link">
            <p className="">
            </p>

            <img src={hscode_img} width="100%" alt="" />
          </Tab>
          <Tab eventKey="origin" title="Origin" tabClassName="tab-link">
            <p className="">
            </p>
          </Tab>
          <Tab
            eventKey="healthCert"
            title="Health Certificate"
            tabClassName="tab-link"
          >
            <p className="">
            </p>
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer
        className="w-100 justify-content-end"
        style={{ flexDirection: "row" }}
      >
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InfoModal;