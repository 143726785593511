/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useCallback, useRef, useEffect } from "react";
import "./newLoad.css";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../Store";
import { Container, Tab, Alert, OverlayTrigger, Popover } from "react-bootstrap";
import LoadInfo from "./LoadInfo";
import OrderForm from "./OrderForm";
import { getCompanyNameFromId, postNewLoad, updateDossierStatus } from "../apiCalls";
import Toast from "../Toast/Toast";
import ProductInfo from "./ProductInfo";
import QAChecklistTab from "./QAChecklistTab/QAChecklistTab.js";
import ConditionalWrapper from "../Util/ConditionalWrapper";
import restartSVG from "../../images/restart.svg";
import "../FileUploader/FileUploader.css";
import ConfirmationModal from "../ConfirmationModal";
import { ErrorBoundary } from "react-error-boundary";
import NewloadError from "../Errors/NewloadError";
import { useLocation } from 'react-router-dom';
import CustomaiteCreateNewLoad from "./CustomaiteCreateNewLoad.js";
import LoadConfirmation from "./LoadConfirmation.js";
import { ShowIf, useSecurity } from "../Security/security";

const Newload = (props) => {
  const initialLoad = useRef(true);
  const navigate = useNavigate();
  const store = useContext(StoreContext);
  const [formComplete, setFormComplete] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [transferid, setTransferid] = useState("");
  const [despatch, setDespatch] = useState("");
  const [destination, setDestination] = useState("");
  const [showConfirmResetModal, setShowConfirmResetModal] = useState(false);
  const [resetTrigger, setResetTrigger] = useState(false);
  const [draftText, setDraftText] = useState(false);
  const [showRequiredFields, setShowRequiredFields] = useState(false);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loadStatus, setLoadStatus] = useState(null);
  const [isLoadSubmittedOnQATabDraft, setIsLoadSubmittedOnQATabDraft] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const list = query.get('list');
  const { validateRole } = useSecurity();

  const customaiteEnabled = parseInt(list) === 2;

  const showQATab = validateRole({ department: "QA" });

  useEffect(() => {
    const fetch = async () => {
      const data = await getCompanyNameFromId(store.clientID[0])
      //console.log('data from newload is', data)
      store.clientIDname[1](data[0].companyName)
    }
    if (store.clientID[0])
      fetch();
  }, [store.clientID[0]])

  const prevPage = useCallback(() => {
    setCurrentTab((prev) => {
      if (prev - 1 >= 0) {
        tabs[prev].valid = true;
        setTabs((oldTabs) => {
          oldTabs[prev].valid = true;
          return [...oldTabs];
        });
        return prev - 1;
      }
      return prev;
    });
  }, []);

  const firstPage = useCallback(() => {
    prevPage(); prevPage();
  });

  const lastPage = useCallback(() => {
    nextPage(); nextPage();
  });

  const backItemsPageFromQATab = useCallback(() => {
    prevPage(); prevPage();
  });

  const nextPage = useCallback(() => {
    setCurrentTab((prev) => {
      if (prev + 1 < tabs.length) {
        setTabs((oldTabs) => {
          oldTabs[prev].valid = true;
          oldTabs[prev + 1].valid = true;
          return [...oldTabs];
        });
        return prev + 1;
      }
      return prev;
    });
  }, []);

  const createLoadToPost = (submitLoadAsDraftOnQATab) => {
    const newLoad = {
      clientID: store.clientID[0],
      Declarant: store.declarant[0],
      name: store.selectName[0],
      jobref: store.jobRef[0],
      consignor: store.consignor[0],
      consignee: store.consignee[0],
      invoicenumber: store.invoicenumber[0],
      fcl: store.selectFcl[0],
      shipStatus: store.shipStatus[0],
      truck: store.truck[0],
      trailerNo: store.trailer[0],
      date: store.date[0],
      direction: store.directionSelected[0],
      countrydespatch: store.despatch[0],
      countrydestination: store.destination[0],
      exitport1: (store.RowImport[0] ? null : store.exit[0]),
      exitport2: (store.RowImport[0] ? null : store.exit2[0]),
      entryport1: (store.RowExport[0] ? null : store.entry[0]),
      entryport2: (store.RowExport[0] ? null : store.entry2[0]),
      invoicecurr: store.invoicecurr[0],
      incotermcode: store.selectincoterm[0],
      officeOfDest: store.officeOfDest[0],
      imo: store.container[0],
      id: (!store.loadId[0] ? null : store.loadId[0]),
      trailerSeal: store.trailerSeal[0],
      controlledGoods: store.controlledGoods[0],
      container: store.container[0],
      GBimport: store.GBimport[0],
      portsModal: store.portsModal[0],
      adjustments: store.adjustments[0],
      packageTotal: store.packageTotal[0],
      draft: (store.isEidr[0] ? 1 : submitLoadAsDraftOnQATab ? 1 : 0),
      incotermLocation: store.incotermLocation[0],
      fromFile: (store.newLoadFromFile[0] ? true : false)
    };
    // pre-validated product items

    setDraftText(store.isEidr[0]);

    newLoad.items = store.validatedRowsReadyToSubmit[0].map((row) => {
      if (isNaN(row.VAT)) {
        row.VAT = store.VatOptions[0].find((opt) => opt.data === (row.VAT?.trim()?.toUpperCase()))?.id;
      }
      if (isNaN(row.preferential)) {
        row.preferential = store.PreferentialOptions[0].find((opt) => opt.data === row.preferential?.trim()?.toUpperCase())?.id;
      }
      row.pref = row.preferential;
      return row;
    });

    newLoad.docTypes = store.docTypes[0]; //.filter(document => document.checked === true); // if we need to remove fields : .map(document => ({...document}));

    return newLoad;
  };

  const handleSubmit = async (event, isDraft, submitLoadAsDraftOnQATab) => {
    event.preventDefault();
    
    setIsLoadSubmittedOnQATabDraft(submitLoadAsDraftOnQATab);
    const newLoad = createLoadToPost(submitLoadAsDraftOnQATab);

    try {
      const data = await postNewLoad(newLoad, "save");
      if (data[0].completed === "Inserted") {
        store.saved[1](data);
        setTransferid(data[0].transferid);
        setDespatch(data[0].despatch);
        setDestination(data[0].destination);

        //from data, get the value of 'isCm8' and if isCm8 == true AND isDraft is false, then make the call to the CM8 API to set the job to 'Done'
        if (data[0].isCm8 && !isDraft) {
          const formData = new FormData();
          formData.append('transferId', data[0].transferid);
          await updateDossierStatus(formData);
        }
        setCurrentTab(showQATab ? 4 : 3) || setFormComplete(true);
      }
    } catch (err) {
      console.log(err);
      alert("Error saving new load to DB");
    }
  };
  
  //Set the overlay information for the Header tab
  useEffect(() => {
    setTabs((prev) => {
      prev[0].overlay = (
        <Popover content>
          <div className="gap-2 bg-white d-flex flex-column">
            <div className="d-flex flex-column">
              <div
                style={{
                  fontWeight: "500",
                  color: "hsl(0 0% 50%)",
                  fontSize: "90%",
                }}
              >
                Movement Type
              </div>
              <div>
                {
                  store.movementOptions[0].find(
                    (option) =>
                      option?.id?.toString() ===
                      store?.directionSelected?.[0]?.toString()
                  )?.data
                }
              </div>
            </div>
            <div className="d-flex flex-column">
              <div
                style={{
                  fontWeight: "500",
                  color: "hsl(0 0% 50%)",
                  fontSize: "90%",
                }}
              >
                Export Port 1
              </div>
              <div>
                {
                  store.exitPortOptions[0].find(
                    (option) =>
                      option?.id?.toString() === store?.exit?.[0]?.toString()
                  )?.data
                }
              </div>
            </div>
            <div className="d-flex flex-column">
              <div
                style={{
                  fontWeight: "500",
                  color: "hsl(0 0% 50%)",
                  fontSize: "90%",
                }}
              >
                Entry Port 1
              </div>
              <div>
                {
                  store.entryPortOptions[0].find(
                    (option) =>
                      option?.id?.toString() === store?.entry?.[0]?.toString()
                  )?.data
                }
              </div>
            </div>
          </div>
        </Popover>
      );
      return [...prev];
    });
  }, [store.directionSelected[0], store.exit[0], store.entry[0]]);

  //Set the overlay information for the Items tab
  useEffect(() => {
    const countObj = store.validatedRowsReadyToSubmit[0].reduce((acc, curr) => {
      const notAHash = `${curr.commoditycode}|${curr.origin}`;
      if (acc[notAHash] !== undefined) {
        acc[notAHash] = acc[notAHash] + 1;
      } else {
        acc[notAHash] = 1;
      }
      return acc;
    }, {});

    setTabs((prev) => {
      prev[1].overlay = (
        <Popover content>
          <div className="gap-2 bg-white d-flex flex-column">
            <div className="d-flex flex-column">
              <div
                style={{
                  fontWeight: "500",
                  color: "hsl(0 0% 50%)",
                  fontSize: "90%",
                }}
              >
                Product Lines
              </div>
              {Object.entries(countObj).map(([key, value]) => (
                <div>
                  {key.split("|").join(" ").toUpperCase()} - {value}x
                </div>
              ))}
            </div>
          </div>
        </Popover>
      );
      return [...prev];
    });
  }, [store.validatedRowsReadyToSubmit[0]]);

  //Set the overlay information for the Order Form tab
  useEffect(() => {
    setTabs((prev) => {
      prev[2].overlay = (
        <Popover content>
          <div className="gap-2 bg-white d-flex flex-column">
            <div className="d-flex flex-column">
              <div
                style={{
                  fontWeight: "500",
                  color: "hsl(0 0% 50%)",
                  fontSize: "90%",
                }}
              >
                Selected Documents
              </div>
              <ul style={{
                  fontSize: "90%",
                  marginLeft: "1rem",
                  paddingLeft: "0"
                }}
              >
                {
                  store.docTypes[0]
                    .filter(doc => doc.checked)
                    .map((doc, index) => (
                      <li key={index}>
                        {doc.title}
                      </li>
                    ))
                }
              </ul>
            </div>
          </div>
        </Popover>
      );
      return [...prev];
    });
  }, [store.docTypes[0]]);

  const tabsLinksStandard = [
    { title: "Header" },
    { title: "Items" },
    { title: "Order Form" },
    { title: "Load Confirmation" },
  ];

  const tabsLinksWithQACheck = [
    { title: "Header" },
    { title: "Items" },
    { title: "Order Form" },
    { title: "QA" },
    { title: "Load Confirmation" },
  ];

  const [tabs, setTabs] = useState(showQATab ? tabsLinksWithQACheck : tabsLinksStandard);

  useEffect(() => {
    if (initialLoad.current === false) {
      invalidateTabs(0);
    }
  }, [store.tadEns[0]]);

  const invalidateTabs = (after) => {
    setTabs((oldTabs) => {
      return [...oldTabs].map((tab, idx) => ({
        ...tab,
        valid: idx < after ? tab.valid : false,
      }));
    });
  };

  const handleSubmitAnotherLoad = useCallback(() => {
    setResetTrigger(true);
    setTimeout(() => {
      navigate("/Newload/", { replace: true });
      setCurrentTab(0);
      setFormComplete(false);
      initialLoad.current = false;
      invalidateTabs(0);
    }, 0);
  }, []);

  const handleResetPage = useCallback(() => {
    store.resetNewLoad();
    setCurrentTab(0);
    window.location.reload();
  }, [store]);

  useEffect(() => {
    initialLoad.current = false;
    store.pasteNewLoad[1](true);
    //check if the current url has no parameters and if it does, print 'no params' to the console
    /* if (window.location.search === "") {
      console.log('no params')
    } */
  }, []);

  //function to check if the button should be disabled or not. Added for CABS-1123
  function isButtonDisabled(idx, tabValid, formComplete, title) {
    //if idx is 1 and !store.clientID[0] and the form is not completed, then return false. Also have the same check for newLoadFromFile, so we can enable the second button if the user has uploaded a RPA file.
    if (idx === 1 && (store.clientID[0] !== null || store.newLoadFromFile[0]) && !formComplete) return false;
    return (currentTab < idx && !tabValid) || formComplete;
  }

  //function to handle changing tabs. Added for CABS-1123
  function handleChangingTab(idx) {
    //check if currentTab === 0 and idx === 1 and store.clientID[0] and store.directionSelected[0] and store.invoicenumber[0] and store.jobRef[0]
    if (currentTab === 0 && idx === 1 && (store.clientID[0] !== null || store.newLoadFromFile[0])) {
      const validTruckNum = (store?.truck[0]) ? store.truck[0].length <= 35 : true;
      const validContainer = (store?.container[0]) ? store.container[0].length <= 17: true;
      
      if (store.directionSelected[0] && store?.invoicenumber[0] && store.jobRef[0]) {
        setShowRequiredFields(false)
        if((store.invoicenumber[0].length <= 35) && validTruckNum && validContainer) {
        setCurrentTab(idx)
        }
      }
      else {
        setShowRequiredFields(true)
        setErrorMsg('To go to the next page, please fill in the Movement Type, Job Reference and Invoice Number fields')
        setAlertOpen(true)
      }
    }
    else {
      setCurrentTab(idx)
    }
  }

  function getButtonClassName(formComplete, currentTab, idx, tab) {
    if (currentTab === 0 && idx === 1) {
      return "tab-control text-eoriblue done";
    }
    else if (formComplete || currentTab > idx || (tab.valid && currentTab < idx)) {
      return "tab-control text-eoriblue done";
    } else if (currentTab === idx) {
      return "tab-control text-eoriblue active";
    } else {
      return "tab-control text-eoriblue neutral";
    }
  }

  const handleTableClicked = useCallback(() => {
    if (isAlertOpen) {
      setAlertOpen(false);
    }
  }, [isAlertOpen]);

  const handleUpdateLoadStatus = (ls) => {
    setLoadStatus(ls)
  }

  return (
    customaiteEnabled ? <CustomaiteCreateNewLoad /> :
      <ErrorBoundary
        FallbackComponent={NewloadError}
        onReset={() => {
          handleResetPage();
        }}
      >
        <Container fluid id="breadcrumb-tabber" className="pt-4 mt-3" onClick={handleTableClicked}>
          <Toast className="error-toast">
            <Alert style={{ maxWidth: "35rem", backgroundColor: "#E4E3ED" }} show={isAlertOpen}>
              <button
                className="btn-close position-absolute top-0 end-0 p-3"
                onClick={() => setAlertOpen(false)}
              />
              <div
                className="floating-box-header-newload align-items-center"
                style={{ margin: 0, padding: 0 }}
              >
                <span>Please fill all the required fields</span>
              </div>
              <p
                style={{ fontSize: "100%", fontWeight: 500 }}
                className="text-danger"
              >
                {errorMsg}
              </p>
            </Alert>
          </Toast>
          <div className="floating-back-button d-none d-lg-block">
            {currentTab > 0 && !formComplete && (
              <button
                className="blue-button-newload"
                style={{
                  borderRadius: "0px 0.75rem 0.75rem 0px",
                  padding: "0.25rem",
                  width: "2.5rem",
                }}
                onClick={prevPage}
              >
                <LeftChevron />
              </button>
            )}
          </div>
          <Container fluid className="">
            <Tab.Container activeKey={currentTab}>
              <Container fluid="lg" className="mb-4 semi-fluid-container">
                <div className="tab-header w-100">
                  {
                    tabs.map((tab, idx) => {
                      const condition = showQATab ? (tab.overlay && currentTab > idx) : (tab.overlay && currentTab > idx && !formComplete);

                      return (
                        <React.Fragment key={idx}>
                          <ConditionalWrapper
                            condition={condition}
                            wrapper={(children) => (
                              <OverlayTrigger
                                placement="bottom"
                                trigger={["hover", "focus"]}
                                overlay={tab.overlay}
                              >
                                {children}
                              </OverlayTrigger>
                            )}
                          >
                            <button
                              className={getButtonClassName(formComplete, currentTab, idx, tab)}
                              disabled={isButtonDisabled(idx, tab.valid, formComplete, tab.title)}
                              onClick={() => handleChangingTab(idx)}
                            >
                              <NumberedCircle
                                i={idx}
                                done={formComplete}
                              />
                              <span>{tab.title}</span>
                            </button>
                          </ConditionalWrapper>
                          {idx !== tabs.length - 1 && (
                            <div
                              className={`horizontal-line flex-grow-1 rounded-pill ${currentTab > idx ? "bg-success" : "bg-gray"
                                }`}
                            />
                          )}
                        </React.Fragment>
                      )
                    })
                  }
                  <div class="flex-grow-1" />
                  <button
                    className="reset-btn"
                    onClick={() => setShowConfirmResetModal(true)}
                  >
                    <span>Reset Load</span>
                    <img
                      style={{ color: "blue" }}
                      src={restartSVG}
                      width="20"
                      alt=""
                    />
                  </button>
                </div>
              </Container>

              <Tab.Content>
                {/* Step 1 - Header */}
                <Tab.Pane eventKey={0}>
                  <LoadInfo
                    nextPage={nextPage}
                    props={props}
                    showRequiredFields={showRequiredFields}
                    updateLoadStatus={handleUpdateLoadStatus}
                  />
                </Tab.Pane>

                {/* Step 2 - Items */}
                <Tab.Pane eventKey={1}>
                  <ProductInfo
                    visible={currentTab === 1}
                    nextPage={nextPage}
                    lastPage={lastPage}
                    submit={(e) => handleSubmit(e, true, false)}
                    resetTrigger={resetTrigger}
                    setResetDone={() => setResetTrigger(false)}
                    prevPage={prevPage}
                    loadStatus={loadStatus}
                    showQATab={showQATab}
                  />
                </Tab.Pane>

                {/* Step 3 - Order Form */}
                <Tab.Pane eventKey={2}>
                  <OrderForm 
                    visible={currentTab === 2}
                    firstPage={firstPage}
                    nextPage={nextPage}
                    submit={(e) => handleSubmit(e, false, false)}
                    showQATab={showQATab}
                    createLoadToPost={createLoadToPost}
                  />
                </Tab.Pane>

                {/* Step 4 - QA */}
                <ShowIf or={[{ department: 'QA' }]}>
                  <Tab.Pane eventKey={3}>
                    <QAChecklistTab
                      visible={currentTab === 3}
                      prevPage={backItemsPageFromQATab}
                      nextPage={nextPage}
                      submitLoadAsDraft={(e) => handleSubmit(e, true, true)}
                      submitLoad={(e) => handleSubmit(e, false, false)}
                    />
                  </Tab.Pane>
                </ShowIf>

                {/* Step 5 - Load Confirmation */}
                <Tab.Pane eventKey={showQATab ? 4 : 3}>
                  <LoadConfirmation
                    newLoadAction={handleSubmitAnotherLoad}
                    listLoadAction={() => navigate("/ListLoads")}
                    titleText={isLoadSubmittedOnQATabDraft ? "Saved as Draft" : draftText ? "Saved as Draft" : "Submitted!"}
                    bodyText={isLoadSubmittedOnQATabDraft ? "saved as draft" : draftText ? "saved as draft" : "submitted"}
                    portalRef={transferid}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Container>
        </Container>

        <ConfirmationModal
          header="Confirm Reset"
          show={showConfirmResetModal}
          onHide={() => setShowConfirmResetModal(false)}
          onConfirm={handleResetPage}
          annimation={true}
        >
          Are you sure you want to reset this load? All current progress will be lost.
        </ConfirmationModal>
      </ErrorBoundary>
  );
};

// these need to be moved to an asset file
const NumberedCircle = ({ i, bg, fg, done }) => (
  <span className={`icon-md ${bg} ${fg} rounded-circle`}>
    {done ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon-sm"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clipRule="evenodd"
        />
      </svg>
    ) : (
      i + 1
    )}
  </span>
);

const LeftChevron = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon-lg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
      clipRule="evenodd"
    />
  </svg>
);

export default Newload;